const menuInnerPaper = {
  backgroundImage: 'none',
  bgcolor: 'primary.main',
  borderColor: 'secondary.main',
  borderWidth: 1.5,
  borderStyle: 'solid',
  borderRadius: 2,
};

export default {
  appBar: {
    bgcolor: 'primary.main',
    borderBottom: '1px solid #2A2D2A',
    '.MuiContainer-root': {
      maxWidth: '100%',
    },
  },
  container: {
    paddingX: {
      xl: '50px !important',
      lg: '50px !important',
      md: '50px !important',
      sm: '20px !important',
      xs: '20px !important',
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerLeftContainer: {
    display: 'flex',
    alignItems: {
      lg: 'flex-end',
      xl: 'flex-end',
      md: 'flex-end',
      sm: 'center',
      xs: 'center',
    },
    columnGap: { lg: 5, xl: 10, md: 10 },
  },
  menuHiddenContent: {
    display: {
      lg: 'none',
      xl: 'none',
      md: 'none',
    },
  },
  mobileMenuButton: {
    bgcolor: '#1F221F',
    borderRadius: 2,
    display: {
      xl: 'none',
      lg: 'none',
      md: 'none',
    },
  },
  mobileMenuInnerPaper: {
    '.Mui-selected': {
      bgcolor: 'transparent !important',
    },
    '& .MuiMenu-paper': { ...menuInnerPaper, width: '150px', color: 'white' },
  },
  userMenuInnerPaper: {
    mt: '60px',
    '& .MuiMenu-paper': {
      ...menuInnerPaper,
      width: '250px',
      padding: '0 20px',
    },
  },
  userMenu: (index: number, options: any) => ({
    gap: 1.5,
    marginTop: index === options.length - 1 ? 6 : 1.5,
    marginBottom: index === options.length - 1 ? 1.5 : 1,
    ':hover': {
      backgroundColor: 'transparent',
      '.MuiTypography-root': {
        color: 'secondary.main',
      },
      '.MuiSvgIcon-root': {
        stroke: '#1D51C2',
      },
    },
  }),
  tabs: {
    display: {
      xs: 'none',
      sm: 'none',
      md: 'flex',
      lg: 'flex',
      xl: 'flex',
    },
    // backgroundColor: 'transparent',
    // '.Mui-selected': {
    //   color: '#1CBC29 !important',
    // },
    // '.Mui-disabled ': {
    //   color: '#757575 !important',
    // },
  },
  tab: { fontWeight: '300', marginBottom: '25px', color: 'white' },
  userMenuOuterContainer: {
    ':hover': {
      cursor: 'pointer',
    },
  },
  userMenuInnerContainer: {
    display: 'flex',
    columnGap: 1,
    alignItems: 'center',
  },
  userMenuInfoContainer: {
    display: {
      xs: 'none',
      sm: 'none',
      md: 'block',
      lg: 'block',
      xl: 'block',
    },
  },
  userMenuDropdownContainer: {
    display: 'flex',
    columnGap: 1.5,
    justifyContent: 'flex-end',
  },
  avatar: {
    width: '50px',
    height: '50px',
  },
};
