import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'components/Layout';
import DashboardService from 'shared/services/dashboard.service';
import { Performance } from 'pages/Customer/CustomerHome/Performance';
import { KPI } from 'pages/Customer/CustomerHome/KPI';
import { Operations } from 'pages/Customer/CustomerHome/Operations';
import { Welcome } from 'pages/Customer/CustomerHome/Welcome';
import ContractsService from 'shared/services/contracts.service';
import { useAuth } from 'shared/hooks/useAuth';
import { CustomModal } from 'components/Modals/CustomModal';
import { WelcomeModal } from 'components/Modals/WelcomeModal';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { MiningOpportunities } from './MiningOppotunities';

export const CustomerHome = () => {
  const [currencyStatistics, setCurrencyStatistics] = useState<any>(null);
  const [roiChart, setRoiChart] = useState<any>(null);
  const [roiStatistics, setRoiStatistics] = useState<any>(null);
  const [contractsExist, setContractsExist] = useState<boolean>(true);
  const [contractlessCurrency, setContractlessCurrency] = useState<any>(null);

  const { customerId } = useParams();
  const { user } = useAuth();

  const checkUserContracts = async () => {
    const { contracts } = await ContractsService.getContractsByUser(user.id);

    if (!contracts.length) {
      setContractsExist(false);
    }
  };

  const getRoi: (contractIds?: string[]) => Promise<void> = async (
    contractIds,
  ) => {
    try {
      const { chart, ...roiStatistics } = await DashboardService.getRoi({
        customerId,
        contractIds,
      });

      setRoiChart(chart);
      return setRoiStatistics(roiStatistics);
    } catch (ex) {}
  };

  const getCurrencyStatistics: () => Promise<void> = async () => {
    const params = {
      customerId: customerId,
    };
    try {
      const data = await DashboardService.getCurrencyStatistics(params);

      return setCurrencyStatistics(data);
    } catch (err: any) {}
  };

  const getContractlessCurrency: () => Promise<void> = async () => {
    const params = {
      customerId: customerId,
    };
    try {
      const data = await DashboardService.getContractlessCurrency(params);

      return setContractlessCurrency(data);
    } catch (err: any) {}
  };

  useEffect(() => {
    checkUserContracts();
    getCurrencyStatistics();
    getRoi();
    getContractlessCurrency();
  }, []);

  return (
    <Layout backgroundHeight="450px">
      <Welcome />
      <Operations currencyStatistics={currencyStatistics} />
      <KPI statistics={currencyStatistics} />
      <MiningOpportunities statistics={contractlessCurrency} />
      <Performance statistics={roiStatistics} chart={roiChart} />
      {user && user.role === UserRole.CUSTOMER && (
        <CustomModal open={!contractsExist} onClose={() => {}} maxWidth="700px">
          <WelcomeModal />
        </CustomModal>
      )}
    </Layout>
  );
};
