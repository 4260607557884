import { ColumnsType } from 'components/Tables/BaseTable/types';
import { formatBtc, formatKaspa } from 'shared/utils/formatter';

export const getColumns = (coinAbb: string): ColumnsType[] => {
  return [
    {
      label: 'Earnings Date',
      minWidth: 100,
      align: 'left',
      render: ({ timestamp }) => `${timestamp.toLocaleString().slice(0, 10)}`,
    },
    {
      id: 'hashrate',
      label: 'Daily Hashrate(TH/s)',
      minWidth: 100,
      align: 'left',
    },
    {
      id: 'earnings',
      label: `Earnings(${coinAbb})`,
      minWidth: 100,
      align: 'left',
      render: ({ earnings }) =>
        `${coinAbb === 'KAS' ? formatKaspa(earnings) : formatBtc(earnings)}`,
    },
    {
      id: 'percentage',
      label: 'Percentages Theoric',
      minWidth: 100,
      align: 'left',
    },
  ];
};
