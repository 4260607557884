import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { Upfront } from 'components/CustomerDetails/types';
import { SectionHeading } from 'components/SectionHeading';
import moment from 'moment/moment';
import { useMemo } from 'react';
import { formatBtc, formatKaspa, formatUsd } from 'shared/utils/formatter';

const EarnedBtcStatistic = (props: any) => {
  const { earnedBtc, upfront, title, info, coinAbb } = props;

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
      <InfoCard
        showDivider={false}
        styles={{ height: 'auto' }}
        info={info || title}
        subtitle={title}
        content={
          <>
            <Typography color="#828282">Earned {coinAbb} share</Typography>
            {earnedBtc !== null ? (
              <Typography
                sx={{
                  fontSize: {
                    xl: '45px',
                    lg: '35px',
                    md: '25px',
                    sm: '45px',
                    xs: '35px',
                  },
                }}
                fontWeight={500}
                color="secondary"
              >
                {`+${earnedBtc} ${coinAbb ? coinAbb : 'BTC'}`}
              </Typography>
            ) : (
              <Skeleton height={67.5} />
            )}
            <Typography color="#828282">Upfront payments</Typography>
            {upfront !== null ? (
              <Typography
                sx={{
                  fontSize: {
                    xl: '45px',
                    lg: '35px',
                    md: '25px',
                    sm: '45px',
                    xs: '35px',
                  },
                }}
                fontWeight={500}
                color="secondary"
              >
                {`$${upfront} USD`}
              </Typography>
            ) : (
              <Skeleton height={67.5} />
            )}
          </>
        }
      />
    </Grid>
  );
};

type Props = {
  statistics?: Upfront | null;
  titleColor?: string;
  coinAbb?: string;
};
export const EarnedBtc = ({ statistics, titleColor, coinAbb }: Props) => {
  const sections = useMemo(
    () => [
      {
        title: `Current month (${moment().format('MMMM yyyy')})`,
        earnedBtc: statistics
          ? coinAbb === 'KAS'
            ? formatKaspa(statistics?.btcThisMonth)
            : formatBtc(statistics?.btcThisMonth)
          : null,
        upfront: statistics ? formatUsd(statistics?.upfrontThisMonth) : null,
      },
      {
        title: `Previous month (${moment().subtract('1', 'months').format('MMMM yyyy')})`,
        earnedBtc: statistics
          ? coinAbb === 'KAS'
            ? formatKaspa(statistics?.btcLastMonth)
            : formatBtc(statistics?.btcLastMonth)
          : null,
        upfront: statistics ? formatUsd(statistics?.upfrontLastMonth) : null,
      },
      {
        title: `Last 6 months (${moment().subtract('6', 'months').format('MMMM yyyy')})`,
        info: `For the period between ${moment().subtract('6', 'months').format('MMMM yyyy')} and ${moment().format('MMMM yyyy')}`,
        earnedBtc: statistics
          ? coinAbb === 'KAS'
            ? formatKaspa(statistics?.btcLastHalfYear)
            : formatBtc(statistics?.btcLastHalfYear)
          : null,
        upfront: statistics ? formatUsd(statistics?.upfrontLastHalfYear) : null,
      },
    ],
    [statistics],
  );

  return (
    <Box>
      <SectionHeading
        sx={{
          color: titleColor,
          marginBottom: '32px',
        }}
        name="Earnings update"
      />
      <Grid container rowSpacing={2} columnSpacing={2}>
        {sections.map((section) => (
          <EarnedBtcStatistic {...section} coinAbb={coinAbb} />
        ))}
      </Grid>
    </Box>
  );
};
