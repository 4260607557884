import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Typography } from '@mui/material';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { ChevronBackIcon } from 'components/Icons/ChevronBack';
import { useNotification } from 'shared/hooks/useNotification';
import AuthService from 'shared/services/auth.service';
import { styles } from './styles';
import { Dispatch, memo, SetStateAction, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ChevronForwardIcon } from 'components/Icons/ChevronForward';

type Props = {
  nextStep: () => void;
  prevStep: () => void;
  email: string;
  setEmailToken: Dispatch<SetStateAction<string | undefined>>;
};
export const CheckEmail = memo(
  ({ nextStep, prevStep, email, setEmailToken }: Props) => {
    const { showSnackbar } = useNotification();
    const schema = yup.object().shape({
      confirmationCode: yup.string().required('Code is required'),
    });

    const {
      control,
      handleSubmit,
      formState: { errors, isDirty },
    } = useForm<{ confirmationCode: string }>({
      resolver: yupResolver(schema),
    });

    const onSubmit = useCallback(
      async (payload: { confirmationCode: string }) => {
        try {
          const { emailToken } = await AuthService.confirmEmailCode({
            ...payload,
            email,
          });
          setEmailToken(emailToken);
          nextStep();
        } catch (err: any) {
          showSnackbar(err.error, 'error');
        }
      },
      [email],
    );

    return (
      <Box sx={styles.container}>
        <Typography sx={styles.title}>Check you Email</Typography>
        <Typography sx={styles.subtitle}>
          We have sent a confirmation code to create an account to {email}
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ControlledTextField
            dark
            labelColor="white"
            required
            control={control}
            name="confirmationCode"
            label="Code"
            error={errors}
            width={400}
            placeholder="Enter code"
          />

          <Box sx={styles.buttonsContainer}>
            <Button
              className="signup-button"
              variant="outlined"
              onClick={prevStep}
            >
              <ChevronBackIcon />
              Back
            </Button>
            <Button
              disabled={!isDirty}
              className="signup-button active"
              type="submit"
              variant="outlined"
            >
              Confirm
              <ChevronForwardIcon />
            </Button>
          </Box>
        </form>
      </Box>
    );
  },
);
