import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { ConfirmIcon } from 'components/Icons/Confirm';
import { authButtonStyles } from 'shared/styles/button';

export const PasswordChanged = (props: any) => {
  const navigate = useNavigate();

  return (
    <Box className="contentLogin">
      <Box sx={{ display: 'flex', width: '80%', marginBottom: '15px' }}>
        <Typography
          color=""
          variant="h2"
          sx={{
            color: '#fff',
            fontSize: '48px',
            fontWeight: '600',
            textAlign: 'start',
          }}
        >
          Password changed Successfully
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ bgcolor: '#1cbc290d', borderRadius: '50%' }}>
          <ConfirmIcon />
        </Box>
      </Box>
      <Button
        sx={authButtonStyles}
        variant="contained"
        color="secondary"
        fullWidth
        onClick={() => navigate(routesMapping.login)}
      >
        Login
      </Button>
    </Box>
  );
};
