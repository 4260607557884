import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { Button, IconButton } from '@mui/material';
import styles from './styles';
import { Linkedin } from 'components/Icons/Linkedin';
import { Twitter } from 'components/Icons/Twitter';
import { Instagram, KeyboardArrowRight } from '@mui/icons-material';
import LayoutNavigateBar from '../LayoutNavigateBar';
import { LogoLink } from '../LogoLink';

export const Footer = () => {
  const [anchorElMobileMenu, setAnchorElMobileMenu] =
    useState<null | HTMLElement>(null);

  const handleOpenMobileRoutes = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    setAnchorElMobileMenu(event.currentTarget);
  };

  const handleCloseMobileRoutes = () => setAnchorElMobileMenu(null);

  return (
    <AppBar position="static" elevation={0} sx={styles.appBar}>
      <Container sx={styles.container}>
        <Toolbar disableGutters>
          <Box sx={styles.headerContainer}>
            <>
              <IconButton
                onClick={handleOpenMobileRoutes}
                sx={styles.mobileMenuButton}
              >
                <MenuIcon
                  sx={{
                    color: 'white',
                  }}
                />
              </IconButton>
              <Menu
                sx={styles.mobileMenuInnerPaper}
                anchorEl={anchorElMobileMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElMobileMenu)}
                onClose={handleCloseMobileRoutes}
              >
                <LayoutNavigateBar />
              </Menu>
            </>
            <Box sx={styles.headerLeftContainer}>
              <LogoLink />
              <Box display="flex" columnGap={2} sx={styles.tabs}>
                <LayoutNavigateBar />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 2,
              }}
            >
              <Linkedin />
              <Instagram
                sx={{
                  fontSize: 25,
                }}
              />
              <Twitter />
              <Button
                sx={{
                  borderColor: '#FBFBFB',
                  borderRadius: 2,
                }}
                endIcon={<KeyboardArrowRight />}
                variant="outlined"
              >
                Contact us
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
