export const signUpContainer = {
  width: '100%',
  height: '100%',
  backgroundColor: 'primary.main',
  padding: '60px',
  color: 'white',
  '& button.signup-button': {
    border: '1px solid #1D4FBB',
    color: '#1D4FBB',
    '& svg path': {
      transition: '250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      stroke: '#1D4FBB',
    },
    '&:hover svg path': {
      stroke: 'white',
    },
    '&.active': {
      backgroundColor: '#1D51C2',
      color: 'white',
      '& svg path': {
        stroke: 'white',
      },
    },
    '&:disabled': {
      border: '1px solid #545E73',
      backgroundColor: '#545E73',
    },
  },
};
