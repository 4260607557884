import { Box, Skeleton, Typography } from '@mui/material';
import { Card } from 'components/Cards/Card';
import { formatUsd } from 'shared/utils/formatter';

type Props = {
  totalUpfront?: number;
  totalSales?: number;
};

export const TotalUpfront = (props: Props) => {
  const { totalUpfront, totalSales } = props;

  return (
    <Card
      styles={{
        padding: '20px',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: '20px',
            }}
            variant="h5"
            fontWeight="500"
          >
            Total sales
          </Typography>
          <Typography
            sx={{
              fontSize: '32px',
              marginTop: '5px',
              fontWeight: '600',
              letterSpacing: '-2.325px',
              lineHeight: '56px',
            }}
          >
            {typeof totalSales === 'number' ? (
              <>${formatUsd(totalSales)} USD</>
            ) : (
              <Skeleton variant="rectangular" height={56} width={300} />
            )}
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: '20px',
            }}
            variant="h5"
            fontWeight="500"
          >
            Total upfront payments
          </Typography>
          <Typography
            sx={{
              fontSize: '32px',
              marginTop: '5px',
              fontWeight: '600',
              letterSpacing: '-2.325px',
              lineHeight: '56px',
            }}
          >
            {typeof totalUpfront === 'number' ? (
              <>${formatUsd(totalUpfront)} USD</>
            ) : (
              <Skeleton variant="rectangular" height={56} width={300} />
            )}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
