import { Box, Avatar, Typography, Link } from '@mui/material';
import { LinkIcon } from 'components/Icons/LinkIcon';
import { ProtocolTable } from 'components/Tables/ProtocolTables/ProtocolTable';
import { ProtocolFarmingTable } from 'components/Tables/ProtocolTables/ProtocolFarmingTable';
import { formatCurrency, formatDecimals } from 'shared/utils/formatter';
import { Lending } from './Lending';
import { ProtocolLiquidityPoolTable } from 'components/Tables/ProtocolTables/ProtocolLiquidityPoolTable';

export const WalletProtocols = (props: any) => {
  const { protocols } = props;

  const transformDataForTable = (data: any) => {
    const balance = data?.map(
      (item: any) => `${formatDecimals(item.amount)} ${item.optimizedSymbol}`,
    );
    const logos = data.map((item: any) => item.logoUrl);
    const optimizedSymbols = data.map((item: any) => item.optimizedSymbol);

    return [
      {
        balance,
        logos,
        optimizedSymbols,
      },
    ];
  };

  const renderContent = (item: any, protocol: any) => {
    switch (item.name) {
      case 'Lending':
        return <Lending portfolioItem={item} />;
      case 'Vesting':
        return (
          <ProtocolTable
            type="vesting"
            elements={[{ ...item.detail.token, endAt: item.detail.endAt }]}
          />
        );
      case 'Locked':
        return (
          <ProtocolTable
            type="locked"
            elements={item.detail.supplyTokenList.map((element: any) => ({
              ...element,
              unlockedAt: item.detail.unlockedAt,
            }))}
          />
        );
      case 'Leveraged Farming':
        return (
          <ProtocolTable
            type="leveraged farming"
            elements={item.detail.supplyTokenList}
            additionalElements={{
              borrowedTokenAmount: item.detail.borrowTokenList[0].amount,
              borrowedTokenSymbol:
                item.detail.borrowTokenList[0].optimizedSymbol,
              debtRatio: item.detail.debtRatio,
              summaryValue: protocol.summaryValue,
            }}
          />
        );
      case 'Farming':
        return (
          <ProtocolFarmingTable
            elements={transformDataForTable(item.detail.supplyTokenList)}
            rewards={item.detail.rewardTokenList}
            usdFarmingValue={item.stats.assetUsdValue}
          />
        );
      case 'Liquidity Pool':
        return (
          <ProtocolLiquidityPoolTable
            elements={transformDataForTable(item.detail.supplyTokenList)}
            usdFarmingValue={item.stats.assetUsdValue}
          />
        );
      case 'Staked':
      case 'Deposit':
      case 'Yield':
      case 'Rewards':
        return <ProtocolTable elements={item.detail.supplyTokenList} />;

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '40px',
        marginTop: '40px',
      }}
    >
      {protocols.map((protocol: any) => (
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '10px',
              justifyContent: 'space-between',
              rowGap: '10px',
              marginBottom: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '10px',
              }}
            >
              <Avatar
                src={protocol.logo}
                sx={{ width: '40px', height: '40px' }}
              />
              <Typography
                sx={{
                  fontSize: '28px',
                  color: '#081427',
                  fontWeight: '500',
                }}
              >
                {protocol.name}
              </Typography>
              <Link href={protocol.siteUrl} target="_blank">
                <LinkIcon />
              </Link>
            </Box>
            <Typography
              sx={{ fontSize: '28px', color: '#081427', fontWeight: '500' }}
            >
              {formatCurrency(protocol.summaryValue, false)}
            </Typography>
          </Box>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}
          >
            {protocol.portfolioItemList.map((item: any) => (
              <Box
                key={item.name}
                sx={{
                  backgroundColor: '#FBFBFB',
                  borderRadius: '20px',
                  padding: '20px',
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: '500',
                      marginBottom: '20px',
                    }}
                  >
                    {item.name}
                  </Typography>
                  {item.detail.healthRate ? (
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: '5px',
                        marginRight: '20px',
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#7B7B7B',
                        }}
                      >
                        Health rate
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '16px',
                          fontWeight: '500',
                          color: '#1D4FBB',
                        }}
                      >
                        {item.detail.healthRate > 10
                          ? '>10'
                          : item.detail.healthRate?.toFixed(2)}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
                {renderContent(item, protocol)}
              </Box>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
