import { Box } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import CustomersTable from 'components/Tables/CustomersTable';

export const Customers = () => {
  return (
    <Box>
      <SectionHeading
        name="Customers"
        sx={{
          marginBottom: '30px',
        }}
      />
      <CustomersTable />
    </Box>
  );
};
