import { useMemo } from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import CalendarImage from '../../../assets/images/calendar.svg';

const StyledDatePicker = styled(DatePicker)`
  padding-left: 10px;
  width: 100%;
  border-radius: 10px;
  outline: none;
  background-position: 95% center;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.23);
  cursor: pointer;
  font-size: 16px;
  height: 54px;

  @media (max-width: 600px) {
    font-size: 12px !important;
    height: 40px !important;
  }

  @media (max-width: 960px) {
    font-size: 15px;
    height: 50px;
  }

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.8);
  }

  &:focus {
    border: 2px solid #1d4fbb !important;
  }
`;

export const ControlledDatePicker = (props: any) => {
  const {
    label,
    control,
    name,
    defaultValue,
    error,
    required,
    datePickerProps,
    placeholder = 'MM-DD-YYYY',
    dateFormat = 'MM-DD-yyyy',
    isDateTime = false,
    disabled = false,
    minDate,
    maxDate,
  } = props;

  const dateTimeProps = useMemo(
    () =>
      isDateTime
        ? {
            showTimeSelect: true,
          }
        : {},
    [isDateTime],
  );

  const format = useMemo(
    () => (isDateTime ? 'YYYY-MM-DD HH:mm' : dateFormat),
    [isDateTime],
  );

  return (
    <Box
      width="100%"
      sx={{
        '& .react-datepicker-wrapper': {
          width: '100%',
        },
      }}
    >
      <Box display="flex" columnGap={0.5} marginY={0.5}>
        <Typography
          fontWeight="500"
          sx={{
            fontSize: {
              xs: '14px',
              sm: '15px',
              md: '16px',
            },
            lineHeight: '15px',
            color: '#666666',
            marginTop: '10px',
          }}
        >
          {label}
        </Typography>
        {required && (
          <Typography
            sx={{
              fontSize: {
                xs: '13px',
                sm: '14px',
                md: '15px',
              },
              color: '#ff4242',
            }}
          >
            *
          </Typography>
        )}
      </Box>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <>
            <StyledDatePicker
              disabled={disabled}
              minDate={minDate}
              maxDate={maxDate}
              sx={{
                backgroundImage: `url(${CalendarImage})`,
                border: error[name] && '1px solid #ff4242 !important',
              }}
              autoComplete="off"
              showPopperArrow
              placeholderText={placeholder}
              selected={
                field.value && moment(field.value, format, true).isValid()
                  ? moment(field.value, format).toDate()
                  : null
              }
              {...field}
              onChange={(date: any) => {
                return field.onChange(moment(date).format(format));
              }}
              {...datePickerProps}
              {...dateTimeProps}
              dateFormat="Pp"
            />
            <FormHelperText sx={{ marginX: '14px' }}>
              {!!error[name] ? error[name]?.message : ''}
            </FormHelperText>
          </>
        )}
      />
      <FormHelperText sx={{ marginX: '14px' }}>
        {!!error[name] ? error[name]?.message : ''}
      </FormHelperText>
    </Box>
  );
};
