import { Box } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { RenderField } from 'pages/UserData';
import { fieldsStyle, fieldsStyleGray } from 'pages/UserData/styles';

type Props = {
  contactPerson?: string;
  email?: string;
  signedBy?: string;
};
export const Details = ({ contactPerson, email, signedBy }: Props) => {
  return (
    <Box>
      <SectionHeading
        sx={{
          marginBottom: '32px',
        }}
        name="Customer details"
      />
      <Box sx={{ backgroundColor: 'white' }}>
        <Box
          sx={{
            borderRadius: 5,
            overflow: 'hidden',
            maxWidth: '1000',
            border: '1px solid #E0E0E0',
          }}
        >
          <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
            {RenderField('Contact person', contactPerson)}
          </Box>
          <Box sx={fieldsStyle}>{RenderField('Email', email)}</Box>
          <Box sx={{ ...fieldsStyleGray, borderRadius: '0px' }}>
            {RenderField('Signed by', signedBy)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
