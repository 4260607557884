import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography, IconButton, Tabs, Tab } from '@mui/material';
import { CloudUpload, Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNotification } from 'shared/hooks/useNotification';
import FilesService from 'shared/services/files.service';
import InvoicesService from 'shared/services/invoices.service';
import { IFormInput } from './types';
import moment from 'moment';
import { InvoiceType } from 'shared/utils/enum/InvoiceEnum';
import { VisuallyHiddenInput } from 'components/VisuallyHiddenInput';
import { ControlledSelect } from 'components/ControlledInputs/Select';
import { ControlledDatePicker } from 'components/ControlledInputs/DatePicker';
import { ControlledTextField } from 'components/ControlledInputs/TextField';

export const InvoiceForm = (props: any): JSX.Element => {
  const { setShowModal, editData, onFinish } = props;

  const schema = yup.object().shape({
    amount: yup
      .number()
      .transform((value: any) => (Number.isNaN(value) ? null : value))
      .required('Amount is a required field'),
    date: yup.string().required('Date is a required field'),
    status: yup.string(),
  });

  const { showSnackbar } = useNotification();
  const [pdf, setPdf] = useState<any>();
  const [tab, setTab] = useState(0);

  const invoiceType = tab === 0 ? 'Invoice' : 'Credit';

  const formValues: any = useMemo(
    () =>
      editData
        ? {
            amount: editData.amount,
            date: moment(editData.date).format('MM-YYYY'),
          }
        : {
            amount: null,
            date: null,
            pdf: null,
          },
    [editData],
  );

  const statusOptions = [
    {
      value: 'Paid',
      name: 'Paid',
    },
    {
      value: 'Unpaid',
      name: 'Unpaid',
    },
  ];

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: formValues,
  });

  const { id } = useParams();

  useEffect(() => {
    // Making MUI Select component work with react-hook-form
    setValue('contract', formValues.contract);
  }, [formValues]);

  const onSubmit = async (invoiceData: any): Promise<void> => {
    const payload = {
      ...invoiceData,
      contract: id,
      pdf: '',
      type: tab === 0 ? InvoiceType.Invoice : InvoiceType.Credit,
    };

    if (pdf) {
      const invoiceFormData = new FormData();
      invoiceFormData.append('file', pdf);
      const { fileName: invoiceFileName } =
        await FilesService.uploadFile(invoiceFormData);
      payload.pdf = invoiceFileName;
    }

    if (editData && editData.pdf) payload.pdf = editData.pdf;

    try {
      if (editData) {
        await InvoicesService.updateInvoice(editData.id, payload);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await InvoicesService.createInvoice(payload);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
    }
  };

  useEffect(() => {
    if (!editData) return;
    setTab(editData.type === InvoiceType.Invoice ? 0 : 1);
  }, [editData]);

  return (
    <>
      <Box
        sx={{
          padding: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
          <Typography
            variant="h5"
            fontWeight="500"
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            {editData ? 'Edit' : 'Add'} {invoiceType}
          </Typography>
          <Tabs
            TabIndicatorProps={{ style: { backgroundColor: '#008000' } }}
            sx={{ width: '100%', marginTop: '20px' }}
            value={tab}
            onChange={(_, value) => setTab(value)}
          >
            <Tab label="Invoice" />
            <Tab label="Credit" />
          </Tabs>
          <ControlledDatePicker
            datePickerProps={{
              showMonthYearPicker: true,
            }}
            control={control}
            required
            label="Date"
            name="date"
            placeholder="MM-YYYY"
            dateFormat="MM-YYYY"
            error={errors}
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.amount ?? 0}
            name="amount"
            label={invoiceType}
            error={errors}
            placeholder={invoiceType}
            required
            type="number"
          />
          {editData && (
            <ControlledSelect
              control={control}
              name="status"
              label="Status"
              defaultValue={editData?.status ?? ''}
              error={errors}
              options={statusOptions}
            />
          )}
          <div className="wrapInput">
            <p className="label">PDF {invoiceType}</p>
            <Button
              sx={{ bgcolor: '#008000' }}
              fullWidth
              component="label"
              color="secondary"
              variant="contained"
              startIcon={<CloudUpload />}
            >
              Upload {invoiceType}
              <VisuallyHiddenInput
                onChange={(e) => setPdf(e.target.files![0])}
                accept="application/pdf"
                type="file"
              />
            </Button>
            {pdf && (
              <Box display="flex" gap={1} alignItems="center" mt={1}>
                {pdf.name.length > 18
                  ? `${pdf.name.substring(0, 18)}...`
                  : pdf.name}
                <IconButton onClick={() => setPdf(null)} aria-label="delete">
                  <Delete sx={{ color: '#008000' }} />
                </IconButton>
              </Box>
            )}
          </div>
          <Box
            sx={{
              marginTop: 2,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button className="submitButton" type="submit">
              {editData ? 'Edit Invoice' : 'Add Invoice'}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};
