import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesMapping, userRedirectionMapping } from 'routes/mappings';
import VerificationService from 'shared/services/verification.service';
import { existingUsers } from 'shared/constants/existing-users';
import { useAuth } from 'shared/hooks/useAuth';
import { verificationEligibleUsers } from './users';

export const VerificationContext = createContext({});

export const VerificationProvider = (props: any) => {
  const { children } = props;
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isNewUser, setIsNewUser] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const location = useLocation();

  const getVerificationInfo = async () => {
    if (!user) return;
    const isVerificationEligible = verificationEligibleUsers.includes(
      user.role,
    );

    setIsNewUser(
      isVerificationEligible && !existingUsers.includes(user?.email),
    );

    const { status } = await VerificationService.getVerificationData();
    setIsVerified(isVerificationEligible && status === 'APPROVED');

    if (!existingUsers.includes(user.email) && isVerificationEligible) {
      if (status !== 'APPROVED') return navigate(routesMapping.verification);
      if (
        status === 'APPROVED' &&
        location.pathname === routesMapping.verification
      ) {
        return navigate(userRedirectionMapping[user.role]);
      }
    }
  };

  useEffect(() => {
    if (!user) return;

    getVerificationInfo();
  }, [user, location.pathname]);

  return (
    <VerificationContext.Provider
      value={{
        isNewUser,
        isVerified,
        getVerificationInfo,
      }}
    >
      {children}
    </VerificationContext.Provider>
  );
};
