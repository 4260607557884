import { ColumnsType } from 'components/Tables/BaseTable/types';
import moment from 'moment';

export const columns: ColumnsType[] = [
  {
    label: 'Date',
    minWidth: 100,
    render: ({ timestamp }) => {
      return timestamp
        ? moment.unix(timestamp).format('YYYY-MM-DD')
        : 'Date Undefined';
    },
  },
  {
    id: 'transaction',
    label: 'BTC Transaction',
    minWidth: 100,
    render: ({ amount }) => `${amount} BTC`,
  },
];
