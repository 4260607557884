import { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SvgComponentHide } from 'components/Icons/Hide';
import { SvgComponentSee } from 'components/Icons/See';
import { EmailIcon } from 'components/Icons/Email';
import { LockIcon } from 'components/Icons/Lock';
import { Progress } from 'components/Progress';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { useAuth } from 'shared/hooks/useAuth';
import { schema } from './schema';
import { IFormInput } from './type';
import { useNotification } from 'shared/hooks/useNotification';
import { Link, useNavigate } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { AuthLayout } from 'components/Layout';
import './index.css';
import { authButtonStyles } from 'shared/styles/button';

export const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showSnackbar } = useNotification();
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<IFormInput> = async (loginData) => {
    setIsLoading(true);
    try {
      return await login(loginData);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <form className="contentLogin" onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', width: '100%', marginBottom: '15px' }}>
            <Typography
              color=""
              variant="h2"
              sx={{
                color: '#fff',
                fontSize: '48px',
                fontWeight: '600',
                textAlign: 'start',
              }}
            >
              HEARST
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', width: '100%', marginBottom: '30px' }}>
            <Typography variant="h3" sx={{ color: 'white', fontSize: '12px' }}>
              Your monitoring Dashboard. Please login to your account.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              rowGap: '30px',
            }}
          >
            <Box className="dataBox">
              <ControlledTextField
                control={control}
                placeholder="name.example@gmail.com"
                name="email"
                fullWidth
                margin="dense"
                error={errors}
                dark
                textFieldProps={{
                  InputProps: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Box>
            <Box className="dataBox">
              <ControlledTextField
                control={control}
                defaultValue=""
                name="password"
                placeholder="Password"
                error={errors}
                type={showPassword ? 'text' : 'password'}
                dark
                textFieldProps={{
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? (
                            <SvgComponentSee />
                          ) : (
                            <SvgComponentHide />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  },
                }}
              />
            </Box>
            <Box
              onClick={() => navigate(routesMapping.forgotPassword)}
              sx={{
                ':hover': {
                  cursor: 'pointer',
                },
                color: 'white',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                textAlign: 'end',
              }}
              marginTop={2}
            >
              Forgot Password?
            </Box>
            <Button
              sx={authButtonStyles}
              variant="contained"
              color="secondary"
              type="submit"
              fullWidth
            >
              {!isLoading ? 'Login' : <Progress color="inherit" size={20} />}
            </Button>
          </Box>

          <Typography
            variant="h3"
            sx={{
              marginTop: '24px',
              color: 'white',
              fontSize: '16px',
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                color: 'white',
                fontSize: '16px',
                display: 'inline',
                opacity: 0.6,
                marginRight: '5px',
              }}
            >
              Don’t have an account?
            </Typography>
            <Link to={routesMapping.signUp}>
              <Typography
                sx={{
                  color: 'white',
                  display: 'inline',
                  fontSize: '16px',
                  textAlign: 'center',
                }}
              >
                Sign up
              </Typography>
            </Link>
          </Typography>
        </Box>
      </form>
    </AuthLayout>
  );
};
