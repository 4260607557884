export const KycIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8359 17.5028C16.7568 17.5028 17.5033 16.7563 17.5033 15.8354"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5028 15.8357V12.501"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8325 17.5028H11.6641"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.33186 2.49707H4.16345C3.2426 2.49707 2.49609 3.24357 2.49609 4.16443V6.66547"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8341 9.16651H12.4994C11.5785 9.16651 10.832 8.42001 10.832 7.49915V4.16443C10.832 3.24357 11.5785 2.49707 12.4994 2.49707H15.8341C16.755 2.49707 17.5015 3.24357 17.5015 4.16443V7.49915C17.5015 8.42001 16.755 9.16651 15.8341 9.16651Z"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.3005 5.12158L13.8833 6.53884L13.0312 5.68932"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.33186 17.503H2.91293C2.68272 17.503 2.49609 17.3164 2.49609 17.0862V16.7477C2.49884 15.7847 3.27882 15.0047 4.24182 15.002H7.00297C7.96597 15.0047 8.74595 15.7847 8.7487 16.7477V17.0862C8.7487 17.3164 8.56207 17.503 8.33186 17.503Z"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.62438 12.9177C4.70439 12.9177 3.95826 12.1726 3.95703 11.2526C3.95581 10.3326 4.69997 9.58547 5.61995 9.58301C6.53994 9.58057 7.28806 10.3237 7.29175 11.2437C7.29352 11.6871 7.11863 12.1129 6.80575 12.427C6.49286 12.7412 6.06775 12.9177 5.62438 12.9177Z"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
