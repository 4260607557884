export const CameraIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.4477 6.11455L20.9428 7.60964C21.1929 7.85969 21.532 8.00016 21.8856 8.00016H25.3333C26.8061 8.00016 28 9.19407 28 10.6668V22.6668C28 24.1396 26.8061 25.3335 25.3333 25.3335H6.66667C5.19391 25.3335 4 24.1396 4 22.6668V10.6668C4 9.19407 5.19391 8.00016 6.66667 8.00016H10.1144C10.468 8.00016 10.8071 7.85969 11.0572 7.60964L12.5523 6.11455C13.0524 5.61445 13.7307 5.3335 14.4379 5.3335H17.5621C18.2693 5.3335 18.9476 5.61445 19.4477 6.11455Z"
      stroke={props.color ? props.color : '#081427'}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle
      cx="16.0013"
      cy="15.9998"
      r="5.33333"
      stroke={props.color ? props.color : '#081427'}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.0654 12.0003C24.0653 12.0371 24.0355 12.0668 23.9987 12.0668C23.9619 12.0668 23.932 12.037 23.932 12.0002C23.932 11.9634 23.9618 11.9335 23.9986 11.9335C24.0163 11.9335 24.0333 11.9405 24.0458 11.953C24.0584 11.9656 24.0654 11.9825 24.0654 12.0003"
      stroke={props.color ? props.color : '#081427'}
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
