import { Avatar, Box } from '@mui/material';

export const columns = (rewards: any, usdFarmingValue: any) => {
  const formattedRewards = rewards.map((item: any) => {
    const amount = item.amount.toFixed(4); // Round the amount to 4 decimal places
    const symbol = item.optimizedSymbol;
    const value = (item.amount * item.price).toFixed(2); // Calculate the value and round to 2 decimal places
    return `${amount} ${symbol} ($${value})`;
  });

  const formatCurrencyWithThreshold = (value: any) => {
    if (value < 0.01) {
      return '<$0.01';
    }
    return `$${value.toFixed(2)}`;
  };

  return [
    {
      id: 'pool',
      label: 'Pool',
      minWidth: 180,
      width: 500,
      align: 'left',
      sortable: false,
      render: (token: any) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex' }}>
            {token.logos.map((url: any, index: number) => {
              return (
                <Avatar
                  key={index}
                  sx={{
                    width: '30px',
                    height: '30px',
                  }}
                  src={url}
                />
              );
            })}
          </Box>
          <Box sx={{ marginLeft: '10px' }}>
            {token.optimizedSymbols.join('+')}
          </Box>
        </Box>
      ),
    },
    {
      id: 'balance',
      label: 'Balance',
      minWidth: 120,
      align: 'left',
      sortable: false,
      render: (token: any) => (
        <Box>
          {token?.balance.map((balance: any) => {
            return <Box>{balance}</Box>;
          })}
        </Box>
      ),
    },
    {
      id: 'rewards',
      label: 'Rewards',
      minWidth: 120,
      align: 'left',
      sortable: false,
      render: () => (
        <Box>
          {formattedRewards.map((reward: any) => {
            return <Box>{reward}</Box>;
          })}
        </Box>
      ),
    },
    {
      id: 'value',
      label: 'USD Value',
      minWidth: 120,
      align: 'right',
      sortable: false,
      render: () => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '6px',
            marginLeft: '20px',
          }}
        >
          {formatCurrencyWithThreshold(usdFarmingValue)}
        </Box>
      ),
    },
  ];
};
