export const SecurityIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.16406"
      y="8.33301"
      width="11.6667"
      height="9.16667"
      rx="2.49896"
      stroke="#081427"
      stroke-width="1.24948"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66406 8.33333V5.83333V5.83333C6.66406 3.99238 8.15645 2.5 9.9974 2.5V2.5C11.8383 2.5 13.3307 3.99238 13.3307 5.83333V5.83333V8.33333"
      stroke="#081427"
      stroke-width="1.24948"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.53225 12.4704C7.54417 12.4823 7.54774 12.5002 7.54129 12.5158C7.53484 12.5314 7.51964 12.5415 7.50278 12.5415C7.48592 12.5415 7.47072 12.5314 7.46427 12.5158C7.45781 12.5002 7.46138 12.4823 7.4733 12.4704V12.4704C7.48958 12.4541 7.51594 12.4541 7.53221 12.4704"
      stroke="#081427"
      stroke-width="1.24948"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0323 12.4704C10.0442 12.4823 10.0477 12.5002 10.0413 12.5158C10.0348 12.5314 10.0196 12.5415 10.0028 12.5415C9.98592 12.5415 9.97072 12.5314 9.96427 12.5158C9.95781 12.5002 9.96138 12.4823 9.9733 12.4704V12.4704C9.98958 12.4541 10.0159 12.4541 10.0322 12.4704"
      stroke="#081427"
      stroke-width="1.24948"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.4977 14.1668H11.6641"
      stroke="#081427"
      stroke-width="1.24948"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
