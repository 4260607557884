import { CustomersDetails } from 'components/CustomerDetails';
import { Upfront } from 'components/CustomerDetails/types';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import ContractsService from 'shared/services/contracts.service';
import UsersService from 'shared/services/users.service';
import { coins } from 'shared/utils/currency';

export const AffiliateCustomerDetails = () => {
  const { customerId } = useParams();
  const [customer, setCustomer] = useState<any>();
  const [upfront, setUpfront] = useState<Upfront>();
  const [owner, setOwner] = useState<any>();
  const [selectedCurrency, setSelectedCurrency] = useState(coins[0]);

  const fetchUpfront = async () => {
    const data = await ContractsService.getAffiliateCustomerUpfront(
      customerId!,
    );
    setUpfront(data);
  };

  const fetchCustomer = async () => {
    const user = await UsersService.getAffiliateCustomer(customerId!);
    setCustomer(user);
  };

  const fetchOwner = async () => {
    const user = await UsersService.getUser(customer.ownerId);
    setOwner(user);
  };

  useEffect(() => {
    if (!customerId) return;
    fetchUpfront();
    fetchCustomer();
    customer && fetchOwner();
  }, [customerId]);

  return (
    <CustomersDetails
      backNavigation={routesMapping.affiliateOperation}
      customer={customer}
      owner={owner}
      upfront={upfront}
      setSelectedCurrency={setSelectedCurrency}
      selectedCurrency={selectedCurrency}
      coins={coins}
    />
  );
};
