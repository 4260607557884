import { Statistics } from 'components/Statistics';
import { useMemo } from 'react';
import { SectionHeading } from 'components/SectionHeading';
import { DashboardStatistics } from 'shared/types/dashboard';

type Props = {
  statistics?: DashboardStatistics;
};
export const Operations = ({ statistics }: Props) => {
  const statisticsData = useMemo(
    () => [
      {
        title: 'Total Investments',
        value: statistics ? statistics.totalInvestment : null,
        currency: '$',
      },
      {
        title: 'Total Bitcoin hashrate',
        value: statistics ? statistics.bitcoinHashRate.toFixed(2) : null,
        currency: 'PH/s',
      },
      {
        title: 'Total Kaspa hashrate',
        value: statistics ? statistics.kaspaHashRate.toFixed(2) : null,
        currency: 'PH/s',
      },
    ],
    [statistics],
  );

  return (
    <>
      <SectionHeading
        sx={{
          color: '#fff',
        }}
        name="Your operations"
      />
      <Statistics statistics={statisticsData} />
    </>
  );
};
