import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

export const SeeMoreButton = (props: any) => {
  const { route } = props;

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Button
      onClick={() =>
        props.onClick
          ? props.onClick()
          : navigate(route, { state: { prevPath: location.pathname } })
      }
      size="small"
      variant="contained"
      color="secondary"
      sx={{
        borderRadius: 1.5,
        paddingX: 2.5,
        paddingY: 1,
      }}
    >
      {'See more  >'}
    </Button>
  );
};
