import { ColumnsType } from 'components/Tables/BaseTable/types';
import { useMemo } from 'react';
import { makeUrl } from 'routes/makeUrl';
import { routesMapping } from 'routes/mappings';
import { SeeMoreButton } from '../BaseTable/SeeMoreButton';
import {
  MachineName,
  ContractDetails,
  Miners,
  Hosting,
  Location,
} from './cells';
import { useLocation, useParams } from 'react-router-dom';

const SeeMore = ({ id }: any) => {
  const { pathname } = useLocation();
  const { customerId } = useParams();

  const url = useMemo(() => {
    switch (true) {
      case pathname.includes('customer-view') && pathname.startsWith('/admin'):
        return makeUrl(routesMapping.adminViewCustomerContract, {
          customerId,
          id,
        });
      case pathname.includes('customer-view') &&
        pathname.startsWith('/affiliate'):
        return makeUrl(routesMapping.affiliateViewCustomerContract, {
          customerId,
          id,
        });
      default:
        return makeUrl(routesMapping.customerContract, { id });
    }
  }, [pathname]);

  return <SeeMoreButton route={url} />;
};

export const columns: ColumnsType[] = [
  {
    id: 'name',
    label: 'Contracts',
    minWidth: 50,
    align: 'center',
    render: ({ name, hostingCost, totalInvestment, hearstUpfront }) => (
      <ContractDetails
        name={name}
        amount={hostingCost + totalInvestment + hearstUpfront}
      />
    ),
  },
  {
    id: 'machineType',
    label: 'Machine Name',
    minWidth: 150,
    align: 'left',
    render: ({ machineType, machineModel }) => (
      <MachineName machineType={machineType} machineModel={machineModel} />
    ),
  },
  {
    id: 'numberOfMachines',
    label: '# Miners',
    minWidth: 150,
    align: 'left',
    render: ({ numberOfMachines }) => (
      <Miners numberOfMachines={numberOfMachines} />
    ),
  },
  {
    id: 'electricityCost',
    label: 'Hosting',
    minWidth: 150,
    align: 'left',
    render: ({ electricityCost }) => <Hosting cost={electricityCost} />,
  },
  {
    id: 'location',
    label: 'Country Based',
    minWidth: 150,
    align: 'left',
    render: ({ location }) => <Location country={location} />,
  },
  {
    label: 'Details',
    sortable: false,
    minWidth: 150,
    align: 'left',
    render: ({ id }) => <SeeMore id={id} />,
  },
];
