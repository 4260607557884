type GetDynamicStyles = {
  colored: boolean;
  small: boolean;
};

export const getDynamicStyles = ({ colored, small }: GetDynamicStyles) => {
  const styles: any = {
    height: small ? 'auto' : '175px',
  };

  if (colored) {
    styles.background = 'linear-gradient(180deg, #1D4FBB 0%, #7498E6 100%)';
    styles.border = 'none';
  }

  return styles;
};
