import { Grid } from '@mui/material';
import { BitcoinInfoCard } from 'components/Cards/BitcoinInfoCard';
import { threeRowGridItem } from '../styles';
import { SectionHeading } from 'components/SectionHeading';
import { ChainCard } from './ChainCard';

export const Chain = (props: any) => {
  const { data, currency, currencyValue, coinAbb } = props;

  return (
    <>
      <SectionHeading
        sx={{
          color: '#fff',
        }}
        name={`${currency} Chain`}
      />
      <Grid marginTop={1} container rowSpacing={2} columnSpacing={2}>
        <Grid {...threeRowGridItem}>
          <BitcoinInfoCard
            showFilter
            styles={{
              height: '550px',
            }}
            subtitle="Revenue"
            title=""
            coinAbb={coinAbb}
            currency={currency}
            currencyValue={currencyValue}
          />
        </Grid>
        <Grid {...threeRowGridItem}>
          <ChainCard type="revenue" data={data} />
        </Grid>
        <Grid {...threeRowGridItem}>
          <ChainCard type="difficulty" data={data} />
        </Grid>
      </Grid>
    </>
  );
};
