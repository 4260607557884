import { Box, Tooltip } from '@mui/material';
import { memo, useMemo } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import countryCodes from 'shared/constants/country-codes.json';

export const WorldMap = memo((props: any) => {
  const { countries } = props;

  const mappedCountries = useMemo(
    () =>
      countries.map(
        (country: any) =>
          countryCodes.countries.find((i) => i.alpha2 === country.location)
            ?.alpha3,
      ),
    [countries],
  );

  return (
    <Box
      padding={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      paddingTop={2}
      sx={{ margin: { lg: '0 10px', xl: '0 10px' } }}
    >
      <ComposableMap
        style={{ width: '100%' }}
        height={400}
        projectionConfig={{
          rotate: [-15, 0, 0],
          scale: 150,
        }}
      >
        <Geographies geography="/countries.json">
          {({ geographies }) =>
            geographies.map((geo) => {
              const tooltipContent = countryCodes.countries.find(
                (i) => i.alpha3 === geo.id,
              )?.country;

              return (
                <Tooltip key={geo.rsmKey} title={tooltipContent || ''} arrow>
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    fill={
                      mappedCountries.includes(geo.id) ? '#3056D3' : '#98AEF8'
                    }
                    stroke="#fff"
                    strokeWidth={0.5}
                    style={{
                      hover: {
                        fill: '#3056D3',
                      },
                    }}
                  />
                </Tooltip>
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </Box>
  );
});
