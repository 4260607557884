import TableHead from '@mui/material/TableHead';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { EnhancedTableHead } from 'components/Tables/RedesignedTable/SortableTableHead';
import { StyledTableRow } from 'components/Tables/RedesignedTable/styles';

type Props = {
  show?: boolean;
  columns: ColumnsType[];
  order: 'asc' | 'desc';
  orderBy: any;
  setOrder: any;
  setOrderBy: any;
};
export const RedesignedTableHead = ({
  columns,
  order,
  orderBy,
  setOrderBy,
  setOrder,
}: Props) => {
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableHead className="tableHead">
      <StyledTableRow>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
        />
      </StyledTableRow>
    </TableHead>
  );
};
