export const ConfirmIcon = (props: any) => (
  <svg
    width="194"
    height="194"
    viewBox="0 0 194 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Icon/Outline/check-circle">
      <path
        id="Icon"
        d="M72.8333 97L88.9444 113.111L121.167 80.8889M169.5 97C169.5 137.041 137.041 169.5 96.9999 169.5C56.9593 169.5 24.4999 137.041 24.4999 97C24.4999 56.9593 56.9593 24.5 96.9999 24.5C137.041 24.5 169.5 56.9593 169.5 97Z"
        stroke="#1CBC29"
        stroke-width="3.86667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
);
