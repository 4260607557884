import { useEffect, useState } from 'react';
import { CustomModal } from 'components/Modals/CustomModal';
import { Box } from '@mui/material';
import UsersService from 'shared/services/users.service';
import { AdminHardwareSupplierDetailsForm } from 'components/Forms/AdminHardwareSupplierDetailsForm';
import { AddCircleOutline } from '@mui/icons-material';

export const AddOpportunityButton = ({
  hardware,
  onFinish,
  setIsLoading,
  setHardwareChange,
  hardwareChange,
}: any) => {
  const [showModal, setShowModal] = useState(false);
  const [supplierAccounts, setSupplaerAccounts] = useState([]);

  const fetchSuppliers = async () => {
    setSupplaerAccounts([]);
    try {
      const response = await UsersService.getAllSuppliers();

      const { users } = response;

      const hardwareSuppliers = users.filter(
        (supplier: any) =>
          supplier.role === 'HardwareSupplier' || supplier.isHardware === true,
      );

      setSupplaerAccounts(hardwareSuppliers);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSuppliers();
  }, []);

  return (
    <Box marginY="10px">
      <CustomModal
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="750px"
      >
        <AdminHardwareSupplierDetailsForm
          setShowModal={setShowModal}
          onFinish={onFinish}
          suppliers={supplierAccounts}
          hardware={hardware}
          setIsLoading={setIsLoading}
          setHardwareChange={setHardwareChange}
          hardwareChange={hardwareChange}
        />
      </CustomModal>
      <Box
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#ffffff',
          border: '1px solid #1D51C2',
          borderRadius: '20px !important',
          color: '#000000',
          padding: '5px 15px',
          width: 'fit-content',
        }}
        className="addAdminStyles"
        onClick={() => setShowModal && setShowModal(true)}
      >
        <AddCircleOutline
          sx={{
            cursor: 'pointer',
            height: 20,
            width: 20,
            borderRadius: 20,
          }}
        />
        Add Opportunity
      </Box>
    </Box>
  );
};
