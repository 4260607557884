export const HearstLogo = (props: any) => {
  return (
    <svg
      width="138"
      height="42"
      viewBox="0 0 138 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="37.5151"
        y="0.383158"
        width="100.033"
        height="41.2337"
        stroke="#FBFBFB"
        strokeWidth="0.766316"
      />
      <path
        d="M124.024 15.8809C125.539 15.8809 126.762 16.3681 127.692 17.3426C128.623 18.3014 129.089 19.6925 129.089 21.5158V29.0368H126.935V21.7044C126.935 20.4155 126.604 19.4253 125.941 18.7337C125.278 18.0421 124.379 17.6963 123.243 17.6963C122.06 17.6963 121.113 18.0814 120.403 18.8516C119.693 19.606 119.338 20.7141 119.338 22.1759V29.0368H117.184V16.0931H119.338V19.1581C119.669 18.105 120.253 17.2955 121.089 16.7296C121.941 16.1638 122.92 15.8809 124.024 15.8809Z"
        fill="#FBFBFB"
      />
      <path
        d="M109.053 15.9322C110.3 15.9322 111.412 16.1994 112.39 16.7338C113.384 17.2682 114.157 18.0384 114.71 19.0444C115.278 20.0503 115.562 21.2291 115.562 22.5809C115.562 23.9326 115.278 25.1115 114.71 26.1174C114.157 27.1077 113.384 27.87 112.39 28.4044C111.412 28.9388 110.3 29.206 109.053 29.206C107.807 29.206 106.686 28.9388 105.692 28.4044C104.714 27.87 103.941 27.1077 103.373 26.1174C102.805 25.1115 102.521 23.9326 102.521 22.5809C102.521 21.2291 102.805 20.0503 103.373 19.0444C103.941 18.0384 104.714 17.2682 105.692 16.7338C106.686 16.1994 107.807 15.9322 109.053 15.9322ZM109.053 17.8183C108.28 17.8183 107.562 17.9912 106.899 18.337C106.252 18.6828 105.724 19.2172 105.314 19.9403C104.919 20.6476 104.722 21.5278 104.722 22.5809C104.722 23.6183 104.919 24.4985 105.314 25.2215C105.724 25.9288 106.252 26.4554 106.899 26.8012C107.562 27.147 108.28 27.3199 109.053 27.3199C109.826 27.3199 110.536 27.147 111.183 26.8012C111.846 26.4554 112.374 25.9288 112.769 25.2215C113.179 24.4985 113.384 23.6183 113.384 22.5809C113.384 21.5278 113.179 20.6476 112.769 19.9403C112.374 19.2172 111.846 18.6828 111.183 18.337C110.536 17.9912 109.826 17.8183 109.053 17.8183Z"
        fill="#FBFBFB"
      />
      <path
        d="M98.3511 12.2998C98.3511 11.9069 98.4852 11.5925 98.7534 11.3567C99.0216 11.1052 99.3766 10.9795 99.8184 10.9795C100.26 10.9795 100.615 11.1052 100.883 11.3567C101.152 11.5925 101.286 11.9069 101.286 12.2998C101.286 12.6928 101.152 13.015 100.883 13.2665C100.615 13.5022 100.26 13.6201 99.8184 13.6201C99.3766 13.6201 99.0216 13.5022 98.7534 13.2665C98.4852 13.015 98.3511 12.6928 98.3511 12.2998ZM100.883 16.0957V29.0394H98.7297V16.0957H100.883Z"
        fill="#FBFBFB"
      />
      <path
        d="M97.288 27.1539V29.04H95.6786C94.3533 29.04 93.3592 28.7256 92.6966 28.0969C92.0497 27.4682 91.7262 26.4151 91.7262 24.9376V17.9588H89.7855V16.0963H91.7262V12.8662H93.9036V16.0963H97.2643V17.9588H93.9036V24.9848C93.9036 25.8178 94.0535 26.3915 94.3533 26.7059C94.6688 27.0045 95.2053 27.1539 95.9626 27.1539H97.288Z"
        fill="#FBFBFB"
      />
      <path
        d="M88.8182 16.0962V29.0399H86.6645V25.9985C86.3332 27.0516 85.7415 27.8611 84.8895 28.4269C84.0532 28.9771 83.075 29.2521 81.9548 29.2521C80.4559 29.2521 79.241 28.7727 78.31 27.8139C77.3791 26.8394 76.9137 25.4484 76.9137 23.6408V16.0962H79.0674V23.4286C79.0674 24.7175 79.3987 25.7156 80.0614 26.4229C80.7241 27.1145 81.6234 27.4603 82.7594 27.4603C83.9428 27.4603 84.8895 27.0831 85.5995 26.3286C86.3095 25.5584 86.6645 24.4424 86.6645 22.9807V16.0962H88.8182Z"
        fill="#FBFBFB"
      />
      <path
        d="M74.9863 11.5896V29.0366H72.8326V11.5896H74.9863Z"
        fill="#FBFBFB"
      />
      <path
        d="M64.7014 15.9322C65.9479 15.9322 67.0602 16.1994 68.0385 16.7338C69.0325 17.2682 69.8056 18.0384 70.3578 19.0444C70.9258 20.0503 71.2098 21.2291 71.2098 22.5809C71.2098 23.9326 70.9258 25.1115 70.3578 26.1174C69.8056 27.1077 69.0325 27.87 68.0385 28.4044C67.0602 28.9388 65.9479 29.206 64.7014 29.206C63.4549 29.206 62.3347 28.9388 61.3407 28.4044C60.3625 27.87 59.5893 27.1077 59.0213 26.1174C58.4533 25.1115 58.1693 23.9326 58.1693 22.5809C58.1693 21.2291 58.4533 20.0503 59.0213 19.0444C59.5893 18.0384 60.3625 17.2682 61.3407 16.7338C62.3347 16.1994 63.4549 15.9322 64.7014 15.9322ZM64.7014 17.8183C63.9283 17.8183 63.2104 17.9912 62.5477 18.337C61.9008 18.6828 61.3722 19.2172 60.962 19.9403C60.5676 20.6476 60.3703 21.5278 60.3703 22.5809C60.3703 23.6183 60.5676 24.4985 60.962 25.2215C61.3722 25.9288 61.9008 26.4554 62.5477 26.8012C63.2104 27.147 63.9283 27.3199 64.7014 27.3199C65.4745 27.3199 66.1845 27.147 66.8314 26.8012C67.4941 26.4554 68.0227 25.9288 68.4171 25.2215C68.8274 24.4985 69.0325 23.6183 69.0325 22.5809C69.0325 21.5278 68.8274 20.6476 68.4171 19.9403C68.0227 19.2172 67.4941 18.6828 66.8314 18.337C66.1845 17.9912 65.4745 17.8183 64.7014 17.8183Z"
        fill="#FBFBFB"
      />
      <path
        d="M57.0976 24.7242C57.0976 25.5101 56.9083 26.241 56.5296 26.9169C56.1509 27.5927 55.575 28.1429 54.8019 28.5672C54.0288 28.9916 53.0742 29.2038 51.9382 29.2038C50.8337 29.2038 49.8476 29.0073 48.9798 28.6144C48.1278 28.2057 47.4415 27.6556 46.9208 26.964C46.4159 26.2567 46.124 25.4551 46.0451 24.5592H48.3408C48.3882 25.0307 48.5459 25.4944 48.8142 25.9502C49.0982 26.3903 49.4926 26.7597 49.9975 27.0583C50.5182 27.3412 51.1414 27.4827 51.8672 27.4827C52.8139 27.4827 53.5397 27.2312 54.0446 26.7282C54.5495 26.2253 54.8019 25.5808 54.8019 24.7949C54.8019 24.1191 54.6205 23.5689 54.2576 23.1445C53.9105 22.7202 53.4766 22.3901 52.9559 22.1543C52.4352 21.9028 51.7252 21.6356 50.8259 21.3527C49.7687 21.0069 48.9167 20.6847 48.2698 20.386C47.6387 20.0717 47.0944 19.608 46.6368 18.995C46.195 18.382 45.9741 17.5725 45.9741 16.5666C45.9741 15.7021 46.1792 14.9398 46.5895 14.2796C47.0155 13.6195 47.615 13.1086 48.3882 12.7471C49.1771 12.3856 50.1001 12.2048 51.1572 12.2048C52.7981 12.2048 54.0998 12.6056 55.0623 13.4073C56.0247 14.1932 56.5848 15.1991 56.7426 16.4251H54.4232C54.297 15.765 53.9578 15.1834 53.4056 14.6804C52.8533 14.1774 52.1039 13.926 51.1572 13.926C50.2894 13.926 49.5952 14.1539 49.0745 14.6097C48.5538 15.0498 48.2935 15.6785 48.2935 16.4958C48.2935 17.1403 48.4592 17.6668 48.7905 18.0755C49.1376 18.4684 49.5636 18.7828 50.0685 19.0186C50.5734 19.2386 51.2676 19.4901 52.1512 19.773C53.2241 20.1188 54.084 20.4489 54.7309 20.7633C55.3936 21.0776 55.9537 21.557 56.4113 22.2015C56.8688 22.8302 57.0976 23.6711 57.0976 24.7242Z"
        fill="#FBFBFB"
      />
      <rect
        x="0.383158"
        y="0.383158"
        width="36.8126"
        height="41.2337"
        stroke="#FBFBFB"
        stroke-width="0.766316"
      />
      <path
        d="M11.5205 19.6897L18.264 26.2148H22.4058L13.5928 17.6841L11.5205 19.6897Z"
        fill="#FBFBFB"
      />
      <path
        d="M26.4731 22.7839L19.7297 16.2589H15.5878L24.4008 24.7882L26.4731 22.7839Z"
        fill="#FBFBFB"
      />
      <path
        d="M14.2871 11.9368H10.5906V30.0631H14.2871V11.9368Z"
        fill="#FBFBFB"
      />
      <path
        d="M26.9882 11.9368H23.2916V30.0631H26.9882V11.9368Z"
        fill="#FBFBFB"
      />
    </svg>
  );
};
