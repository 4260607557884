import { Box, Button, Skeleton, SvgIcon, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { CopyIcon } from 'components/Icons/Copy';
import { useNavigate } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { useNotification } from 'shared/hooks/useNotification';
import { HearstIcon } from 'components/Icons/HearstIcon';
import { formatBtc } from 'shared/utils/formatter';

type Props = {
  wallet: {
    amountInCoin: number;
    walletAddress: string;
  };
};
export const AffiliateWalletBalance = ({ wallet }: Props) => {
  const navigate = useNavigate();
  const { showSnackbar } = useNotification();

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    showSnackbar('Address Copied', 'success');
  };

  return (
    <InfoCard
      headerColor="white"
      infoIconColor="white"
      showDivider={false}
      fullHeight
      styles={{
        height: '100%',
        background: 'linear-gradient(120deg, #4C74CD 20%, #8FA8E3 90%)',
        border: 'unset',
        color: 'white',
        justifyContent: 'unset',
      }}
      content={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: '20px',
              color: '#fff',
            }}
            variant="h5"
            fontWeight="500"
          >
            Wallet balance
          </Typography>
          <Box sx={{ display: 'flex', marginBottom: '20px' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <Box>
                {!wallet ? (
                  <Skeleton variant="rectangular" height={50} />
                ) : (
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '32px',
                        fontWeight: '600',
                        color: 'white',
                        letterSpacing: '-2.325px',
                        lineHeight: '56px',
                      }}
                    >
                      {`${formatBtc(wallet.amountInCoin)} BTC`}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: {
                    xs: 'flex',
                    sm: 'none',
                    md: 'none',
                    lg: 'none',
                    xl: 'none',
                  },
                  justifyContent: 'flex-end',
                  paddingRight: '20px',
                }}
              >
                <HearstIcon />
              </Box>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: {
                  xs: 'none',
                  sm: 'flex',
                  md: 'flex',
                  lg: 'flex',
                  xl: 'flex',
                },
                justifyContent: 'flex-end',
                minWidth: '275px',
                paddingRight: '40px',
              }}
            >
              <HearstIcon />
            </Box>
          </Box>
          {wallet ? (
            <Box
              sx={{
                display: {
                  xs: 'block',
                  sm: 'block',
                  md: 'flex',
                  lg: 'flex',
                  xl: 'flex',
                },
                justifyContent: 'space-between',
                gap: '20px',
              }}
            >
              <Box
                sx={{
                  fontSize: '24px',
                  fontWeight: '400',
                  border: '1px solid white',
                  borderRadius: '10px',
                  display: 'flex',
                  overflow: 'hidden',
                  flexShrink: '1',
                  justifyContent: 'space-between',
                  padding: '10px 24px',
                  columnGap: '38px',
                  marginBottom: {
                    xs: '20px',
                    sm: '20px',
                    md: '0px',
                    lg: '0px',
                    xl: '0px',
                  },
                }}
              >
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: 'white',
                    fontSize: '22px',
                    fontWeight: '400',
                  }}
                >
                  {wallet.walletAddress}
                </Typography>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '10px',
                  }}
                >
                  <SvgIcon
                    sx={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      handleCopy(wallet.walletAddress);
                    }}
                  >
                    <CopyIcon />
                  </SvgIcon>
                </Typography>
              </Box>
              <Box
                sx={{
                  minWidth: '209px',
                }}
              >
                <Button
                  disabled={
                    wallet.walletAddress === 'No wallet address found' ||
                    wallet.walletAddress === 'Invalid wallet address' ||
                    !wallet.walletAddress
                  }
                  type="button"
                  sx={{
                    color: '#081427',
                    backgroundColor: '#fff',
                    height: '100%',
                    padding: '10px 24px',
                    fontWeight: '400',
                    fontSize: '18px',
                    width: '100%',
                    borderRadius: '10px',
                    letterSpacing: '-0.6px',
                  }}
                  onClick={() => navigate(routesMapping.affiliateTransactions)}
                >
                  Transaction history
                </Button>
              </Box>
            </Box>
          ) : (
            <Skeleton variant="rectangular" height={65} />
          )}
        </Box>
      }
    />
  );
};
