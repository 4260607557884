import { Box, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { StyledTableCell } from './styles';

export const EnhancedTableHead = (props: any) => {
  const { order, orderBy, onRequestSort, columns } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      {columns.map((column: any) => (
        <StyledTableCell
          key={column.id}
          sortDirection={orderBy === column.id ? order : false}
          style={{
            minWidth: column.minWidth,
            width: column?.width,
            fontSize: '14px',
            fontWeight: '600',
          }}
          align={column.align}
        >
          {column.sortable === undefined || column.sortable ? (
            <TableSortLabel
              style={{ color: '#1D51C2' }}
              active
              direction={orderBy === column.id ? order : 'asc'}
              onClick={createSortHandler(column.id)}
            >
              {column.label}
              {orderBy === column.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          ) : (
            <>{column.label}</>
          )}
        </StyledTableCell>
      ))}
    </>
  );
};
