import { Box, Button, Typography } from '@mui/material';
import { ChevronBackIcon } from 'components/Icons/ChevronBack';
import { ChevronForwardIcon } from 'components/Icons/ChevronForward';
import { AccountTypeState } from 'pages/SignUp/types';
import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { styles } from './styles';

type Props = {
  nextStep: () => void;
  setAccountType: Dispatch<SetStateAction<AccountTypeState>>;
};
export const AccountType = ({ nextStep, setAccountType }: Props) => {
  const navigate = useNavigate();
  const [accountType, setType] = useState<AccountTypeState>();

  const handleNextStepClick = () => {
    if (accountType) {
      setAccountType(accountType);
      nextStep();
    }
  };

  const handleBackClick = () => {
    navigate(routesMapping.login);
  };

  return (
    <Box sx={styles.container}>
      <Box>
        <Typography sx={styles.title}>Create an account</Typography>
        <Typography sx={styles.subtitle}>Welcome to Hearst</Typography>
      </Box>

      <Box sx={styles.buttons}>
        <Box
          sx={[
            styles.boxButton,
            accountType === 'individual' && styles.boxButtonActive,
          ]}
          onClick={() => setType('individual')}
        >
          Individual
        </Box>
        <Box
          sx={[
            styles.boxButton,
            accountType === 'company' && styles.boxButtonActive,
          ]}
          onClick={() => setType('company')}
        >
          Company
        </Box>
      </Box>

      <Box sx={styles.buttonsContainer}>
        <Button
          className="signup-button"
          variant="outlined"
          onClick={handleBackClick}
        >
          <ChevronBackIcon />
          Back
        </Button>
        <Button
          disabled={!accountType}
          className="signup-button active"
          variant="outlined"
          onClick={handleNextStepClick}
        >
          Next
          <ChevronForwardIcon />
        </Button>
      </Box>
    </Box>
  );
};
