import { Box } from '@mui/material';
import logo from 'assets/images/login.png';
import { Header } from 'components/Layout/Header';
import { useAuth } from 'shared/hooks/useAuth';
import { Footer } from './Footer';
import { HearstLogo } from 'components/Icons/HearstLogo';

export const Layout = (props: any) => {
  const { children, backgroundHeight } = props;
  const { user } = useAuth();

  return (
    <Box
      sx={{
        height: backgroundHeight || '1050px',
        backgroundColor: '#101723',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      }}
    >
      {user && <Header />}
      <Box
        sx={{
          padding: {
            sm: 3,
            xs: 3,
            md: 5,
            lg: 5,
            xl: 5,
          },
          paddingX: 5,
        }}
      >
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export const AuthLayout = (props: any) => {
  const { children } = props;
  return (
    <Box
      className="layoutContainer"
      sx={{
        bgcolor: 'primary.main',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '80px',
        overflow: 'hidden',
        minWidth: '100%',
        minHeight: '100%',
        overflowY: 'hidden',
        borderRadius: '0px',
        padding: '85px 70px',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          rowGap: 10,
        }}
      >
        <HearstLogo height={'80px'} width={'140px'} />
        {children}
      </Box>
      <Box className="login-left-side">
        <img
          style={{
            backgroundColor: 'white',
          }}
          className="imgLogin login-image"
          src={logo}
          alt="login welcome back"
        />
      </Box>
    </Box>
  );
};
