import { Layout } from 'components/Layout';
import AffiliateSubAccountTable from 'components/Tables/AffiliateSubAccountTable';

export const TeamMembers = () => {
  return (
    <Layout backgroundHeight="440px">
      <AffiliateSubAccountTable />
    </Layout>
  );
};
