import { AdminSimulation } from 'components/Tables/AdminSimulationTable';
import { Layout } from 'components/Layout';

export const AffiliateSimulation = () => {
  return (
    <Layout>
      <AdminSimulation />
    </Layout>
  );
};
