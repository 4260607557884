import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  chainDataElement,
  chainDataElementText,
  chainDataHeaderElements,
  chainDataHeaderStyles,
  chainDataStyles,
  chainTableContainerPadding,
  chainTableContainerStyles,
  sectionContainerStyles,
  sectionHeaderStyles,
} from '../styles';
import { Currency } from 'shared/utils/enum/coinEnums';

interface ChartData {
  labels: number[];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    borderWidth: number;
    fill: boolean;
  }[];
}

const bitcoinFeePointsList = [0.026, 0.06, 0.09, 0.12, 0.15];
const litecoinFeePointsList = [0.0006, 0.0015, 0.0024, 0.0033, 0.0042];
const kaspaFeePointsList = [0.4, 2.0, 4.4, 6.8, 9.2];
const alephiumFeePointsList = [4.5, 21, 37.5, 48.5, 54];
const nervosFeePointsList = [0.065, 0.17, 0.28, 0.42, 0.56];

export const RoiChartSection = ({ data }: any) => {
  const xAxisDecimals = useMemo(() => {
    if (
      data.currency === Currency.Bitcoin ||
      data.currency === Currency.BitcoinCash
    )
      return 3;
    if (data.currency === Currency.Litecoin) return 4;
    if (data.currency === Currency.Kaspa) return 2;
  }, [data]);

  const transformedData = useMemo(() => {
    return {
      ...data,
      rois: data.rois.map((roiObj: any) => ({
        ...roiObj,
        roi: roiObj.roi < 0 ? 0 : roiObj.roi,
      })),
    };
  }, [data]);

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: (value: any) => `${value}%`,
        },
      },
      x: {
        ticks: {
          callback: (value: any) => `$${chartData.labels[value]}`,
        },
      },
    },
    elements: {
      line: {
        tension: 0,
      },
      point: {
        radius: 1,
        hoverRadius: 1,
      },
    },
  };

  const handleSectionHeader = useMemo(
    () => (data.currency === Currency.Litecoin ? 'MH/s' : 'TH/s'),
    [data],
  );

  const chartData = useMemo<ChartData>(() => {
    const chartValues: ChartData = {
      labels: [],
      datasets: [
        {
          label: 'ROI',
          data: [],
          borderColor: '#034dbc',
          borderWidth: 1,
          fill: false,
        },
      ],
    };

    transformedData.rois.forEach((point: { rem: any; roi: any }) => {
      chartValues.labels.push(point.rem.toFixed(xAxisDecimals));
      chartValues.datasets[0].data.push(point.roi);
    });

    return chartValues;
  }, [data]);

  const selectedFeePointsList = useMemo(() => {
    if (data.currency === Currency.Litecoin) return litecoinFeePointsList;
    if (data.currency === Currency.Kaspa) return kaspaFeePointsList;
    if (data.currency === Currency.Alephium) return alephiumFeePointsList;
    if (data.currency === Currency.Nervos) return nervosFeePointsList;
    return bitcoinFeePointsList;
  }, [data]);

  function formatNumber(number: any) {
    const [integerPart, decimalPart] = String(number).split('.');

    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return `${formattedInteger}`;
  }

  return (
    <Box sx={sectionContainerStyles}>
      <Typography sx={sectionHeaderStyles}>ROI vs. Rem BTC Chain</Typography>
      <Box sx={chainTableContainerStyles}>
        <Box sx={chainTableContainerPadding}>
          <Box sx={chainDataStyles}>
            <Box sx={chainDataHeaderStyles}>
              <Typography sx={chainDataHeaderElements}>
                Rem $ / {handleSectionHeader}
              </Typography>
              <Typography sx={chainDataHeaderElements}>Revenue</Typography>
              <Typography sx={chainDataHeaderElements}>ROI</Typography>
            </Box>
            {transformedData.rois
              .filter((value: any) => selectedFeePointsList.includes(value.rem))
              .map((c: any, i: number) => (
                <Box sx={chainDataElement} key={i}>
                  <Typography sx={chainDataElementText}>
                    {data.currency === Currency.Litecoin
                      ? c.rem.toFixed(4)
                      : data.currency === Currency.Bitcoin
                        ? c.rem.toFixed(3)
                        : c.rem.toFixed(2)}
                  </Typography>
                  <Typography
                    sx={chainDataElementText}
                  >{`$${formatNumber(c.revenue)}`}</Typography>
                  <Typography sx={chainDataElementText}>
                    {c.roi <= 0 ? '0.00%' : `${c.roi.toFixed(2)}%`}
                  </Typography>
                </Box>
              ))}
          </Box>
          <Box sx={{ height: '100%', width: '55%' }}>
            <Line data={chartData} options={chartOptions} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
