export const EmailIcon = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group">
      <g id="Layer_2">
        <rect
          id="Rectangle"
          x="4"
          y="6"
          width="24"
          height="20"
          rx="5.33333"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Path"
          d="M9.3335 11.979L14.9397 14.4233C15.6167 14.7185 16.3859 14.7197 17.0638 14.4265L22.6668 12.0038"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
);
