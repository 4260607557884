import { submitButton } from 'components/Forms/styles';
import { Progress } from 'components/Progress';
import moment from 'moment';
import { useState } from 'react';
import * as yup from 'yup';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ControlledDatePicker } from 'components/ControlledInputs/DatePicker';
import { SectionHeading } from 'components/SectionHeading';
import { Box, Button, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { itemHeaderStyles } from 'pages/Customer/Operation/Miners/styles';
import { useNotification } from 'shared/hooks/useNotification';
import { downloadElectricityInvoicing } from 'shared/utils/fileDownloader';

const schema = yup.object().shape({
  startDate: yup.string().required('Start Date is a required field'),
  endDate: yup
    .string()
    .required('End Date is a required field')
    .test(
      'is-greater',
      'End date cannot be before Start date',
      function (value) {
        const { startDate }: any = this.parent;
        return !startDate || !value || new Date(value) >= new Date(startDate);
      },
    ),
});

export const Invoicing = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const startDate = useWatch({ control, name: 'startDate' });
  const endDate = useWatch({ control, name: 'endDate' });

  const { showSnackbar } = useNotification();

  const onSubmit = async (payload: any) => {
    const { startDate, endDate } = payload;
    try {
      setIsLoading(true);
      await downloadElectricityInvoicing(startDate, endDate);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SectionHeading
        sx={{
          marginTop: 4,
          marginBottom: 4,
        }}
        name="Electricity balance"
      />
      <InfoCard
        showDivider={false}
        fullHeight
        title=""
        styles={{ width: '100%' }}
        hasAdditionalSpace={false}
        content={
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '10px',
              }}
            >
              <Typography sx={itemHeaderStyles}>
                Estimated monthly electricity cost
              </Typography>
              <Box display="flex" columnGap={5}>
                <ControlledDatePicker
                  disabled={isLoading}
                  label="Start date"
                  required
                  name="startDate"
                  control={control}
                  placeholder="YYYY-MM-DD"
                  dateFormat="YYYY-MM-DD"
                  error={errors}
                  maxDate={moment(endDate).toDate()}
                  defaultValue={moment().format('YYYY-MM-DD')}
                />
                <ControlledDatePicker
                  minDate={moment(startDate).toDate()}
                  maxDate={moment().toDate()}
                  disabled={isLoading}
                  label="End date"
                  required
                  name="endDate"
                  control={control}
                  placeholder="YYYY-MM-DD"
                  dateFormat="YYYY-MM-DD"
                  error={errors}
                  defaultValue={moment().format('YYYY-MM-DD')}
                />
              </Box>

              <Box
                display="flex"
                justifyContent="space-between"
                gap="10px"
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: '14px',
                      sm: '14px',
                      md: '20px',
                      xl: '20px',
                      lg: '20px',
                    },
                  }}
                >
                  * 24 hours for each day are defined based on the UTC time zone
                </Typography>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  gap="10px"
                  sx={{
                    alignItems: {
                      xs: 'flex-end',
                      sm: 'flex-end',
                      md: 'center',
                    },
                  }}
                >
                  {isLoading && <Progress size="1.5rem" />}
                  <Button
                    disabled={isLoading}
                    sx={{ ...submitButton, width: '140px', height: '54px' }}
                    variant="contained"
                    type="submit"
                  >
                    Download
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        }
      />
    </>
  );
};
