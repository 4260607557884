export const SvgComponentSee = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={10}
    fill="none"
    {...props}
  >
    <path
      fill={props.color ? props.color : '#B6B6B6'}
      d="M7.5 1.563A6.106 6.106 0 0 1 13.012 5 6.1 6.1 0 0 1 7.5 8.438 6.1 6.1 0 0 1 1.987 5 6.106 6.106 0 0 1 7.5 1.562Zm0-1.25A7.392 7.392 0 0 0 .625 5 7.392 7.392 0 0 0 7.5 9.688 7.392 7.392 0 0 0 14.375 5 7.392 7.392 0 0 0 7.5.312Zm0 3.124a1.563 1.563 0 1 1-.001 3.127A1.563 1.563 0 0 1 7.5 3.437Zm0-1.25A2.817 2.817 0 0 0 4.687 5 2.817 2.817 0 0 0 7.5 7.813 2.817 2.817 0 0 0 10.313 5 2.817 2.817 0 0 0 7.5 2.187Z"
    />
  </svg>
);
