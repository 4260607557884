import { Box } from '@mui/material';
import { formatCurrency, formatNumber } from 'shared/utils/formatter';
import { CoinAvatarWithSymbol } from './components';
import moment from 'moment';

const VestingTableColumns = [
  {
    id: 'pool',
    label: 'Pool',
    minWidth: 180,
    width: 500,
    align: 'left',
    sortable: false,
    render: (token: any) => CoinAvatarWithSymbol(token),
  },
  {
    id: 'balance',
    label: 'Balance',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: (token: any) => (
      <Box>
        {token?.amount.toFixed(4)} {token?.optimizedSymbol}
      </Box>
    ),
  },
  {
    id: 'endTime',
    label: 'End Time',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: (token: any) => (
      <Box>{moment.unix(token.endAt).format('YYYY/MM/DD HH:mm')}</Box>
    ),
  },
  {
    id: 'value',
    label: 'USD Value',
    minWidth: 120,
    align: 'right',
    sortable: false,
    render: (token: any) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          columnGap: '6px',
          marginLeft: '20px',
        }}
      >
        {formatCurrency(token?.price * token?.amount)}
      </Box>
    ),
  },
];

const StakedTableColumns = [
  {
    id: 'pool',
    label: 'Pool',
    minWidth: 180,
    width: 500,
    align: 'left',
    sortable: false,
    render: (token: any) => CoinAvatarWithSymbol(token),
  },
  {
    id: 'balance',
    label: 'Balance',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: (token: any) => (
      <Box>
        {token?.amount.toFixed(4)} {token?.optimizedSymbol}
      </Box>
    ),
  },
  {
    id: 'endTime',
    label: 'End Time',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: (token: any) => (
      <Box>{moment.unix(token.endAt).format('YYYY/MM/DD HH:mm')}</Box>
    ),
  },
  {
    id: 'value',
    label: 'USD Value',
    minWidth: 120,
    align: 'right',
    sortable: false,
    render: (token: any) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          columnGap: '6px',
          marginLeft: '20px',
        }}
      >
        {formatCurrency(token?.price * token?.amount)}
      </Box>
    ),
  },
];

const LockedTableColumns = [
  {
    id: 'pool',
    label: 'Pool',
    minWidth: 180,
    width: 500,
    align: 'left',
    sortable: false,
    render: (token: any) => CoinAvatarWithSymbol(token),
  },
  {
    id: 'balance',
    label: 'Balance',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: (token: any) => (
      <Box>
        {token?.amount.toFixed(4)} {token?.optimizedSymbol}
      </Box>
    ),
  },
  {
    id: 'unlockTime',
    label: 'Unlock Time',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: (token: any) => (
      <Box>{moment.unix(token.unlockedAt).format('YYYY/MM/DD HH:mm')}</Box>
    ),
  },
  {
    id: 'value',
    label: 'USD Value',
    minWidth: 120,
    align: 'right',
    sortable: false,
    render: (token: any) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          columnGap: '6px',
          marginLeft: '20px',
        }}
      >
        {formatCurrency(token?.price * token?.amount)}
      </Box>
    ),
  },
];

const LavaragedFarmingTableColumns = (fields: any) => {
  return [
    {
      id: 'pool',
      label: 'Pool',
      minWidth: 180,
      align: 'left',
      sortable: false,
      render: (token: any) => CoinAvatarWithSymbol(token),
    },
    {
      id: 'supply',
      label: 'Supply',
      minWidth: 120,
      align: 'left',
      sortable: false,
      render: (token: any) => (
        <Box>
          {token?.amount.toFixed(4)} {token?.optimizedSymbol}
        </Box>
      ),
    },
    {
      id: 'borrow',
      label: 'Borrow',
      minWidth: 120,
      align: 'left',
      sortable: false,
      render: () => (
        <Box>
          {formatNumber(fields.borrowedTokenAmount)}{' '}
          {fields.borrowedTokenSymbol}
        </Box>
      ),
    },
    {
      id: 'debtRatio',
      label: 'Debt Ratio',
      minWidth: 120,
      align: 'left',
      sortable: false,
      render: () => <Box>{(fields.debtRatio * 100).toFixed(2)}%</Box>,
    },
    {
      id: 'value',
      label: 'USD Value',
      minWidth: 120,
      align: 'right',
      sortable: false,
      render: (token: any) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '6px',
            marginLeft: '20px',
          }}
        >
          {formatCurrency(fields.summaryValue)}
        </Box>
      ),
    },
  ];
};

const DefaultTableColumns = [
  {
    id: 'pool',
    label: 'Pool',
    minWidth: 180,
    width: 500,
    align: 'left',
    sortable: false,
    render: (token: any) => CoinAvatarWithSymbol(token),
  },
  {
    id: 'balance',
    label: 'Balance',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: (token: any) => (
      <Box>
        {token?.amount.toFixed(4)} {token?.optimizedSymbol}
      </Box>
    ),
  },
  {
    id: 'value',
    label: 'USD Value',
    minWidth: 120,
    align: 'right',
    sortable: false,
    render: (token: any) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          columnGap: '6px',
          marginLeft: '20px',
        }}
      >
        {formatCurrency(token?.price * token?.amount)}
      </Box>
    ),
  },
];

export const columns = (type: any, additionalElements: any) => {
  switch (type) {
    case 'vesting':
      return VestingTableColumns;
    case 'staked':
      return StakedTableColumns;
    case 'locked':
      return LockedTableColumns;
    case 'leveraged farming':
      return LavaragedFarmingTableColumns(additionalElements);
    default:
      return DefaultTableColumns;
  }
};
