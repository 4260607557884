export const threeRowGridItem = {
  item: true,
  sm: 12,
  xs: 12,
  md: 4,
  lg: 4,
  xl: 4,
};

export const tabsContainer = {
  backgroundColor: '#233040',
  borderRadius: '20px',
  padding: '10px',
  display: 'flex',
};

export const activeTabStyles = {
  width: '50%',
  textAlign: 'center',
  padding: '12px',
  borderRadius: '10px',
  backgroundColor: '#FFFFFF',
  color: '#081427',
  cursor: 'pointer',
};

export const tabStyles = {
  width: '50%',
  textAlign: 'center',
  padding: '12px',
  color: '#FFFFFF',
  cursor: 'pointer',
};
