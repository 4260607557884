export const getHashrateAverage = (data: any) => {
  return data.reduce((acc: any, { date, speed }: any) => {
    if (!acc[date]) {
      acc[date] = 0;
    }

    acc[date] += speed;

    return acc;
  }, {});
};
