export const SvgComponentHide = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={16}
    fill="none"
    {...props}
  >
    <path
      fill="#B6B6B6"
      d="M7.5 4.563A6.106 6.106 0 0 1 13.012 8 6.1 6.1 0 0 1 7.5 11.438 6.1 6.1 0 0 1 1.987 8 6.106 6.106 0 0 1 7.5 4.562Zm0-1.25A7.392 7.392 0 0 0 .625 8 7.392 7.392 0 0 0 7.5 12.688 7.392 7.392 0 0 0 14.375 8 7.392 7.392 0 0 0 7.5 3.312Zm0 3.124a1.563 1.563 0 1 1-.001 3.127A1.563 1.563 0 0 1 7.5 6.437Zm0-1.25A2.817 2.817 0 0 0 4.687 8 2.817 2.817 0 0 0 7.5 10.813 2.817 2.817 0 0 0 10.313 8 2.817 2.817 0 0 0 7.5 5.187Z"
    />
    <path stroke="#B6B6B6" d="m1.354 2.146 12 12" />
  </svg>
);
