import { AffiliateWalletBalance } from 'pages/Affiliate/AffiliateOperation/Balances/WalletBalance';
import { TotalUpfront } from 'pages/Affiliate/AffiliateOperation/Balances/TotalUpfront';
import { SectionHeading } from 'components/SectionHeading';
import { Box, Grid } from '@mui/material';

type Props = {
  totalUpfront?: number;
  totalSales?: number;
  walletData: {
    amountInCoin: number;
    walletAddress: string;
  };
  message?: string;
};

export const Balances = ({ walletData, totalUpfront, totalSales }: Props) => {
  return (
    <Box>
      <SectionHeading
        sx={{
          color: '#fff',
          marginBottom: '32px',
        }}
        name="Balances"
      />
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <AffiliateWalletBalance wallet={walletData} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <TotalUpfront totalUpfront={totalUpfront} totalSales={totalSales} />
        </Grid>
      </Grid>
    </Box>
  );
};
