import {
  DeleteOutline,
  EditOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import { Collapse, Table, TableCell, TableRow } from '@mui/material';
import { BaseTable } from 'components/Tables/BaseTable';
import { StyledTableCell } from 'components/Tables/BaseTable/styles';
import { useState } from 'react';

export const ExpandableRow = (props: any) => {
  const {
    row,
    columns,
    color,
    show,
    nestingKey,
    rowColumns,
    handleEdit,
    onRowDelete,
    onSubRowDelete,
    handleSubTableEdit,
  } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow
        key={row.id}
        sx={{
          '&:hover': {
            td: {
              borderColor: `${color} !important`,
            },
            '& .MuiButton-contained': {
              backgroundColor: `${color} !important`,
              borderColor: `${color} !important`,
              color: 'white',
            },
          },
          '& .MuiButton-contained': {
            backgroundColor: 'transparent',
            color: '#828282',
            border: '1px solid #828282',
            boxShadow: 'none',
          },
        }}
      >
        {show && (
          <StyledTableCell align="center">
            {open ? (
              <KeyboardArrowUp
                sx={{
                  color: 'secondary.main',
                  cursor: 'pointer',
                  marginRight: '20px',
                }}
                onClick={() => setOpen(!open)}
              />
            ) : (
              <KeyboardArrowDown
                sx={{
                  color: 'secondary.main',
                  cursor: 'pointer',
                  marginRight: '20px',
                }}
                onClick={() => setOpen(!open)}
              />
            )}
            <EditOutlined
              onClick={() => {
                handleEdit && handleEdit(row);
              }}
              sx={{
                color: 'secondary.main',
                cursor: 'pointer',
                fontSize: 25,
              }}
            />
            <DeleteOutline
              sx={{
                color: 'secondary.main',
                cursor: 'pointer',
                fontSize: 25,
              }}
              onClick={() => {
                onRowDelete && onRowDelete(row.id);
              }}
            />
          </StyledTableCell>
        )}
        {columns.map((column: any) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align="left">
              {column.render
                ? column.render(row)
                : value !== undefined || value !== null
                  ? value
                  : '-'}
            </TableCell>
          );
        })}
      </TableRow>
      {open && (
        <TableRow sx={{ padding: '0 !important', margin: 0 }}>
          <TableCell sx={{ padding: '0 !important', margin: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="purchases">
                <BaseTable
                  tableName=" "
                  items={row[nestingKey]}
                  columns={rowColumns}
                  searchable={false}
                  paginated={false}
                  handleEdit={handleSubTableEdit}
                  onRowDelete={onSubRowDelete}
                  show={show}
                  addButton={false}
                />
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
