import { RedesignedTable } from 'components/Tables/RedesignedTable';
import { useMemo } from 'react';
import { getColumns } from 'components/Tables/MiningOpportunitiesTable/columns';
import { Currency } from 'shared/utils/enum/coinEnums';
import { Box } from '@mui/material';
import { getCoinInfo } from 'shared/utils/getCoinInfo';

export const CustomerMiningOpportunitiesTable = ({ statistics }: any) => {
  const tableData: any = useMemo(() => {
    if (!statistics) return null;
    return statistics.map((s: Currency) => ({
      currency: Currency[s],
      ...getCoinInfo(Currency[s], 0, 0),
    }));
  }, [statistics]);

  const columns = getColumns();

  return (
    <Box sx={{ width: '100%' }}>
      <RedesignedTable
        tableName=""
        items={tableData}
        columns={columns}
        currentPage={1}
        totalPages={1}
        rowsPerPage={6}
        paginated={false}
      />
    </Box>
  );
};
