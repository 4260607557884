export const threeRowGridItem = {
  item: true,
  sm: 12,
  xs: 12,
  md: 4,
  lg: 4,
  xl: 4,
};

export const sx = {
  fontSize: {
    xl: 50,
    lg: 40,
    md: 25,
    sm: 40,
    xs: 28,
  },
};

export const titleSx = {
  fontSize: {
    xl: 20,
    lg: 18,
    md: 12,
    sm: 25,
    xs: 15,
  },
};

export const dateSx = {
  fontSize: {
    xl: 15,
    lg: 15,
    md: 12,
    sm: 12,
    xs: 10,
  },
};
