import { Skeleton } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { TransactionsTable } from 'components/Tables/TransactionsTable';

export const WalletTransaction = (props: any) => {
  const { transactions, coinAbb } = props;

  return (
    <InfoCard
      subtitle="Transactions"
      showDivider={false}
      title=""
      styles={{ height: '100%' }}
      fullHeight
      content={
        !transactions ? (
          <Skeleton variant="rectangular" height={280} />
        ) : (
          <TransactionsTable transactions={transactions} coinAbb={coinAbb} />
        )
      }
    />
  );
};
