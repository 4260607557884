import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseTable } from 'components/Tables/BaseTable';
import { IFormInput } from 'components/Forms/InvoiceForm/types';
import { columns } from './columns';
import { useNotification } from 'shared/hooks/useNotification';
import InvoicesService from 'shared/services/invoices.service';

export const AllInvoicesTable = (props: any) => {
  const { admin = false, contracts, dates } = props;
  const [invoices, setInvoices] = useState<IFormInput[] | null>(null);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);

  const { customerId } = useParams();

  const { showSnackbar } = useNotification();

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  const fetchInvoices = async () => {
    setInvoices(null);
    try {
      const response = await InvoicesService.getInvoices({
        pageNumber: currentPage,
        limit: rowsPerPage,
        contractIds: contracts,
        dates,
        customerId,
      });

      const { invoices, totalPages } = response;
      setInvoices(invoices);
      setTotalPages(totalPages);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    if (!contracts || !dates) return;
    fetchInvoices();
  }, [contracts, dates, currentPage, rowsPerPage]);

  return (
    <BaseTable
      tableName="Invoices"
      items={invoices}
      columns={columns}
      show={admin}
      totalPages={totalPages}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={handleCountItems}
      rowColor={(row) => row.status === 'Unpaid' && '#EB4C42'}
    />
  );
};
