import { AddCircleOutline } from '@mui/icons-material';
import { SectionHeading } from 'components/SectionHeading';
import { RedesignedTableHead } from './components/RedesignedTableHead/RedesignedTableHead';
import { RedesignedTablePagination } from './components/RedesignedTablePagination/RedesignedTablePagination';
import { RedesignedTableRow } from './components/RedesignedTableRow/RedesignedTableRow';
import { useEffect, useMemo, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, SelectChangeEvent } from '@mui/material';
import { Progress } from 'components/Progress';
import { PropsTypes } from './types';
import { SearchInput } from 'components/SearchInput';
import { useDebounce } from 'shared/hooks/useDebounce';
import { getComparator, stableSort } from './sorting';
import './index.css';

export const RedesignedTable = ({
  onSearch,
  tableName,
  items,
  columns,
  totalPages,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  searchable,
  fetchData,
  paginated = true,
  expandColumns,
  expandPropertyName,
  wrapped = true,
  onAdd,
}: PropsTypes) => {
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState(!items);
  const [search, setSearch] = useState<string>('');
  const debouncedQuery = useDebounce(value, 500);
  const pageNumbers =
    totalPages ?? Math.ceil(items?.length / (rowsPerPage ?? 0));

  const handleChange = (event?: SelectChangeEvent) => {
    if (event) {
      setIsLoading(true);
      setRowsPerPage?.(event.target.value as unknown as number);
    }
  };
  const onSearchChange = async (value: string) => {
    setIsLoading(true);
    setValue(value);
  };
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const inputValue = (event.target as HTMLInputElement).value.trim();
      setSearch(inputValue);
    }
  };

  const [order, setOrder] = useState<any>('asc');
  const [orderBy, setOrderBy] = useState<any>('');

  useEffect(() => {
    setIsLoading(true);
  }, [currentPage]);

  useEffect(() => {
    onSearch && onSearch(debouncedQuery);
  }, [debouncedQuery]);

  useEffect(() => {
    if (search && (debouncedQuery !== search || !debouncedQuery)) {
      setIsLoading(true);
      fetchData && fetchData(debouncedQuery);
    }
  }, [debouncedQuery, currentPage, rowsPerPage, search]);

  useEffect(() => {
    if (items) setIsLoading(false);
  }, [items]);

  useEffect(() => {
    if (
      currentPage &&
      setCurrentPage &&
      pageNumbers < currentPage &&
      pageNumbers !== 0
    ) {
      setCurrentPage(pageNumbers);
    }
  }, [pageNumbers, currentPage]);

  const visibleRows = useMemo(
    () =>
      items && items.length > 0
        ? stableSort(items, getComparator(order, orderBy))
        : [],
    [items, order, orderBy],
  );

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
          }}
        >
          {tableName && (
            <SectionHeading
              sx={{
                marginTop: 4,
                marginBottom: 4,
              }}
              name={tableName}
            />
          )}
          {searchable && (
            <Box sx={{ borderRadius: '0px' }}>
              <SearchInput
                value={value}
                setValue={onSearchChange}
                onKeyDown={handleKeyPress}
              />
            </Box>
          )}
        </Box>
        {onAdd && (
          <Box>
            <Box
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#ffffff',
                border: '1px solid #1D51C2',
                borderRadius: '20px !important',
                color: '#000000',
                padding: '5px 15px',
              }}
              className="addAdminStyles"
              onClick={onAdd}
            >
              <AddCircleOutline
                sx={{
                  cursor: 'pointer',
                  marginRight: '10px',
                  height: 20,
                  width: 20,
                  borderRadius: 20,
                }}
              />
              {'Add'}
            </Box>
          </Box>
        )}
      </Box>
      <Box className={wrapped ? 'tableWrapper' : ''}>
        <Paper
          sx={{
            backgroundColor: '#fff',
            color: '#131613',
            borderRadius: '20px',
            boxShadow: wrapped ? '' : 'none',
          }}
        >
          <Box
            sx={{
              width: '100%',
              overflow: 'hidden',
              borderRadius: '0px',
              marginBottom: 0,
            }}
          >
            <TableContainer
              sx={{
                maxHeight: 440,
                marginBottom: 0,
                padding: 0,
                border: 'none',
              }}
            >
              <Table
                stickyHeader
                className="redesignedTable"
                sx={{
                  border: '1px solid #eaeaea',
                  borderRadius: '16px',
                  borderSpacing: '0 !important',
                }}
              >
                <RedesignedTableHead
                  columns={columns}
                  order={order}
                  orderBy={orderBy}
                  setOrder={setOrder}
                  setOrderBy={setOrderBy}
                />
                <TableBody sx={{ height: 70 }}>
                  {isLoading ? (
                    <Progress className="center" size={24} />
                  ) : !items?.length ? (
                    <Box className="center">EMPTY CONTENT</Box>
                  ) : (
                    items?.length &&
                    visibleRows?.map(
                      (row: Record<string, any>, index: number) => (
                        <RedesignedTableRow
                          index={index}
                          key={row.id}
                          row={row}
                          columns={columns}
                          expandColumns={expandColumns}
                          expandPropertyName={expandPropertyName}
                        />
                      ),
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {paginated ? (
              <RedesignedTablePagination
                currentPage={currentPage}
                handleChange={handleChange}
                totalPages={totalPages}
                rowsPerPage={rowsPerPage}
                setCurrentPage={setCurrentPage}
                pageNumbers={pageNumbers}
                totalItems={items?.length}
              />
            ) : (
              <></>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};
