export const ProfileDarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16406 16.6667C4.16406 14.5833 5.83073 13 7.83073 13H12.0807C14.1641 13 15.7474 14.6667 15.7474 16.6667"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.4963 4.33398C13.913 5.75065 13.913 8.00065 12.4963 9.33398C11.0797 10.6673 8.82966 10.7507 7.49633 9.33398C6.163 7.91732 6.07966 5.66732 7.49633 4.33398C8.913 3.00065 11.0797 3.00065 12.4963 4.33398"
      stroke="#081427"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
