export const tableRowStyles = {
  width: '100%',
  background: '#f7f8fa',
  display: 'flex',
  borderRadius: 0,
};

export const modalTitleStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontWeight: '500',
  fontSize: '20px',
  marginBottom: '30px',
};

export const titleStyles = {
  width: '100%',
  color: '#1D4FBB',
  padding: '20px 30px',
};

export const subTitleStyles = {
  width: '100%',
  color: '#081427',
  padding: '20px 30px',
};

export const markConatinerStyles = {
  background: '#f0f2f9',
  width: '162px',
  height: '162px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '50px 0',
};

export const tableTitleStyles = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  fontWeight: '500',
  fontSize: '20px',
  marginBottom: '30px',
};

export const buttonsContainerStyles = {
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '30px',
};

export const downloadButtonStyles = {
  background: '#1d4fbb',
  color: 'white',
  borderRadius: '10px',
  padding: '0px 20px',
  '&.MuiButtonBase-root:hover': {
    bgcolor: '#1d4fbb',
  },
};

export const finishButtonStyles = {
  marginRight: '10px',
  background: '#eeeeee',
  color: 'black',
  width: '90px',
  borderRadius: '10px',
  '&.MuiButtonBase-root:hover': {
    bgcolor: '#eeeeee',
  },
};
