import { Box } from '@mui/material';
import { ProfileDarkIcon } from 'components/Icons/DarkProfile';
import { SecurityIcon } from 'components/Icons/Security';

const menus = [
  { id: 1, name: 'profile', label: 'Profile details', icon: ProfileDarkIcon },
  { id: 2, name: 'security', label: 'Security', icon: SecurityIcon },
];

export const ProfileLeftSideMenu = (props: any) => {
  const { selectedMenu, setSelectedMenu } = props;

  return (
    <Box
      sx={{
        background: '#fbfbfb',
        padding: '20px',
        width: '30%',
        height: '600px',
        borderRadius: '10px',
      }}
    >
      {menus.map((menu) => (
        <Box
          key={menu.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px 20px',
            cursor: 'pointer',
            backgroundColor:
              selectedMenu === menu.name ? '#D5DDF9' : 'transparent',
            borderRadius: '10px',
          }}
          onClick={() => setSelectedMenu(menu.name)}
        >
          <Box
            sx={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}
          >
            <menu.icon />
          </Box>
          <Box>{menu.label}</Box>
        </Box>
      ))}
    </Box>
  );
};
