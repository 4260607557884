import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

export const ControlledAutocomplete = (props: any) => {
  const {
    label,
    initalLabel,
    optionLabel,
    options,
    value,
    valueText,
    setValueText,
    setValue,
    width,
    control,
    name,
    error,
    errorText,
    multiple,
    disabled,
  } = props;

  return (
    <Box
      width={width ? width : '100%'}
      display="flex"
      flexDirection="column"
      columnGap={0.5}
      rowGap={0.5}
      marginY={0.5}
    >
      {label ? (
        <Box
          display="flex"
          justifyContent="flex-start"
          columnGap={0.5}
          marginY={0.5}
          width="100%"
        >
          <Typography
            fontWeight="500"
            fontSize="16px"
            lineHeight="15px"
            color="#666666"
            marginTop="10px"
          >
            {label}
          </Typography>
        </Box>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={() => (
          <Autocomplete
            disablePortal
            value={value}
            multiple={multiple}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
              setValueText(newInputValue);
            }}
            options={options}
            getOptionLabel={(option) => option[`${optionLabel}`]}
            disabled={disabled}
            sx={{
              width: '100%',
              '& .MuiChip-root': {
                backgroundColor: '#d6ddf9',
                color: '#081427',
                borderRadius: '6px',
              },
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: false,
                }}
                name={name}
                label={!valueText ? initalLabel : ''}
                error={error}
                helperText={error ? errorText : null}
              />
            )}
          />
        )}
      />
    </Box>
  );
};
