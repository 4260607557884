import { Box, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';

export const SummaryWalletCard = (props: any): JSX.Element => {
  const { name, value, logo } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '16px',
        padding: '20px',
        background: 'linear-gradient(to bottom, #1D4FBB 0%, #7498E6 100%)',
        columnGap: '10px',
        alignItems: 'center',
        width: '260px',
      }}
    >
      <Avatar src={logo} />
      <Box>
        <Typography
          sx={{ color: '#FBFBFB', fontSize: '14px', fontWeight: '500' }}
        >
          {name}
        </Typography>
        <Typography
          sx={{ color: '#FBFBFB', fontSize: '20px', fontWeight: '500' }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
};
