import HttpService from './http.service';

class InvoicesService {
  endpoint: string = 'invoices';

  getInvoices = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);

  getContractInvoices = async (id: any, params: any) =>
    await HttpService.get(`${this.endpoint}/contract/${id}`, params);

  getInvoicesFilterDate = async (params: any) =>
    await HttpService.get(`${this.endpoint}/fitlering/dates`, params);

  createInvoice = async (payload: any) =>
    await HttpService.post(`${this.endpoint}`, payload);

  updateInvoice = async (invoiceId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${invoiceId}`, payload);

  deleteInvoice = async (invoiceId: string) =>
    await HttpService.delete(`${this.endpoint}/${invoiceId}`);
}

export default new InvoicesService();
