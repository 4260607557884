import { Dialog, DialogContent, Backdrop } from '@mui/material';
import { IProps } from './types';
import CircularProgress from '@mui/material/CircularProgress';

export const CustomModal = ({
  open,
  onClose,
  children,
  maxWidth,
  backdropOpen = false,
}: IProps): JSX.Element => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          overflowY: 'visible',
          borderRadius: '30px',
          maxWidth: maxWidth || '600px',
        },
      }}
    >
      <DialogContent sx={{ padding: 0 }}>{children}</DialogContent>
      <Backdrop
        open={backdropOpen}
        sx={{
          maxWidth: maxWidth || '600px',
          borderRadius: '28px',
          position: 'absolute',
          backgroundColor: '#fff',
          opacity: '0.6 !important',
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Dialog>
  );
};
