import { Upfront } from 'components/CustomerDetails/types';
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Layout } from 'components/Layout';
import { Balances } from 'pages/Affiliate/AffiliateOperation/Balances';
import { Customers } from 'pages/Affiliate/AffiliateOperation/Customers';
import { EarnedBtc } from 'components/CustomerDetails/EarnedBTC';
import { useNotification } from 'shared/hooks/useNotification';
import BitcoinService from 'shared/services/bitcoin.service';
import contractsService from 'shared/services/contracts.service';

export const AffiliateOperation = () => {
  const [walletData, setWalletData] = useState<any>(null);
  const [upfront, setUpfront] = useState<Upfront>();

  const { showSnackbar } = useNotification();

  const getWalletData = async () => {
    try {
      const { transactions, ...data } = await BitcoinService.getWalletData({});

      return setWalletData(data);
    } catch (err: any) {
      return showSnackbar(err.error, 'error');
    }
  };

  const getTotalUpfront = async () => {
    try {
      const affiliateUpfront = await contractsService.getAffiliateUpfront();

      setUpfront(affiliateUpfront);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    getWalletData();
    getTotalUpfront();
  }, []);

  return (
    <Layout backgroundHeight="480px">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '60px',
          marginTop: '20px',
        }}
      >
        <Balances
          walletData={walletData}
          totalUpfront={upfront?.upfrontTotal}
          totalSales={upfront?.totalSales}
        />
        <EarnedBtc statistics={upfront} />
        <Customers />
      </Box>
    </Layout>
  );
};
