import { Button } from '@mui/material';
import { ContactSalesModal } from 'components/Modals/ContactSalesModal';
import { CustomModal } from 'components/Modals/CustomModal';
import { useState } from 'react';

export const ContactSalesButton = (props: any) => {
  const { row } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <Button
        onClick={() => setOpenModal(true)}
        size="small"
        variant="contained"
        sx={{
          borderRadius: 1.5,
          paddingX: 2.5,
          paddingY: 1,
          color: 'white !important',
          backgroundColor: '#1d51c2 !important',
        }}
      >
        {'Contact sales'}
      </Button>
      <CustomModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="700px"
      >
        <ContactSalesModal coinName={row.coinName} coinLogo={row.logo} />
      </CustomModal>
    </>
  );
};
