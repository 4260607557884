import { Grid } from '@mui/material';
import { StatisticsCard } from 'components/Cards/StatisticsCard';
import { threeRowGridItem } from 'pages/Customer/Operation/styles';

type Props = {
  statistics: {
    title: string;
    value?: string | number | null;
    currency?: string;
  }[];
};
export const Statistics = ({ statistics }: Props) => {
  return (
    <>
      <Grid
        marginTop={0.5}
        marginBottom={5}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        {statistics?.map((statistic: any) => (
          <Grid key={statistic.title} {...threeRowGridItem}>
            <StatisticsCard {...statistic} small colored />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
