import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Active } from 'shared/utils/enum/activeEnums';
import { Skeleton } from '@mui/material';
import {
  createDataForHashrateMeasuredChart,
  createOptionsForHashrateMeasuredChart,
  getAverageHashrateData,
} from 'shared/utils/createData';
import { getHashrateAverage } from 'shared/utils/groupDataAndSumValue';
import { linePlugin } from '../plugins';

export const HashrateChart = (props: any) => {
  const { hashrateData, activeChart } = props;

  const hashrateDataCalculated = useMemo(() => {
    const hourly: any[] = [];
    const daily: any[] = [];

    if (hashrateData?.length) {
      hashrateData?.forEach((item: any) => {
        item?.dailyData && daily.push(...item?.dailyData);
        item?.hourlyData && hourly.push(...item?.hourlyData);
      });
    }

    const dailyAverageHashrate: any = getAverageHashrateData(
      getHashrateAverage(daily),
    );

    const hourlyAverageHashrate: any = getAverageHashrateData(
      getHashrateAverage(hourly),
    );

    return {
      dailyData: dailyAverageHashrate.sort((a: any, b: any) => {
        const aDate: any = new Date(a.date);
        const bDate: any = new Date(b.date);
        return aDate - bDate;
      }),
      hourlyData: hourlyAverageHashrate.sort((a: any, b: any) => {
        const aDate: any = new Date(a.date);
        const bDate: any = new Date(b.date);
        return aDate - bDate;
      }),
    };
  }, [hashrateData]);

  const hashrateMeasuredData = useMemo(() => {
    const speedList =
      hashrateDataCalculated?.[
        activeChart === Active.DAY ? 'dailyData' : 'hourlyData'
      ] || [];

    return createDataForHashrateMeasuredChart(speedList);
  }, [hashrateDataCalculated, activeChart]);

  return (
    <>
      {hashrateData ? (
        <Line
          options={{
            ...createOptionsForHashrateMeasuredChart(
              hashrateMeasuredData,
              activeChart,
            ),
            interaction: {
              mode: 'index',
              intersect: false,
            },
          }}
          data={hashrateMeasuredData}
          plugins={[linePlugin]}
        />
      ) : (
        <Skeleton variant="rectangular" height="100%" />
      )}
    </>
  );
};
