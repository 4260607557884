export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  form: {
    width: '100%',
  },
  title: { color: 'white', fontSize: '48px' },
  inputRow: { display: 'flex', gap: '24px', justifyContent: 'space-between' },
  buttonsContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
};
