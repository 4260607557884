import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  FormControl,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

const rowItems: number[] = [5, 10, 15, 20, 25];

type Props = {
  totalPages?: number;
  currentPage?: number;
  pageNumbers?: number;
  totalItems?: number;
  rowsPerPage?: number;
  setCurrentPage?: (value: number) => void;
  handleChange: (event?: SelectChangeEvent) => void;
};
export const RedesignedTablePagination = ({
  totalItems,
  currentPage,
  pageNumbers,
  rowsPerPage,
  setCurrentPage,
  handleChange,
}: Props) => {
  return (
    <Box className="pagination">
      {!!(currentPage && pageNumbers && totalItems) && (
        <>
          <Box sx={{ color: '#131613', fontSize: '14px' }}>
            Showing {currentPage}-{pageNumbers} of {totalItems ?? 0} results
          </Box>
          <Pagination
            siblingCount={1}
            boundaryCount={1}
            count={pageNumbers}
            page={currentPage}
            onChange={(_, num) => {
              handleChange();
              setCurrentPage?.(num);
            }}
            sx={{
              '& .Mui-selected': {
                color: '#081427',
                border: '1px solid #EAEAEA',
                borderRadius: '6px',
                backgroundColor: 'transparent',
              },
              '& .Mui-disabled': {
                display: 'none',
              },
            }}
          />
          <Box sx={{ minWidth: 120 }}>
            <Typography sx={{ color: '#131613', fontSize: '14px' }}>
              Showing:{' '}
            </Typography>
            <FormControl fullWidth>
              <Select
                value={`${rowsPerPage}`}
                label="Age"
                onChange={handleChange}
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
              >
                {rowItems.map((rowItem: number) => (
                  <MenuItem value={rowItem} key={rowItem}>
                    {rowItem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </>
      )}
    </Box>
  );
};
