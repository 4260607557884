import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { Hashrate } from 'components/CustomerDetails/Activity/Hashrate';
import { InfoCard } from 'components/Cards/InfoCard';
import dashboardService from 'shared/services/dashboard.service';
import { Currency } from 'shared/utils/enum/coinEnums';
import { formatBtc, formatKaspa } from 'shared/utils/formatter';

export const CurrencyRowContent = ({
  coin,
  currency,
  yesterdayEarning,
  accountBalance,
}: any) => {
  const [hashrate, setHashrate] = useState<any>(null);
  const { customerId } = useParams();

  const getHashrateData = async () => {
    const data = await dashboardService.getDashboardHashrateChart({
      currency,
      customerId,
    });

    setHashrate(data);
  };

  useEffect(() => {
    if (!currency) return;

    getHashrateData();
  }, [currency]);

  const yesterdayEarningFormatted = useMemo(() => {
    switch (currency) {
      case Currency.Bitcoin:
        return formatBtc(yesterdayEarning, 6);
      case Currency.Kaspa:
        return formatKaspa(yesterdayEarning, 3);
      default:
        return formatBtc(yesterdayEarning);
    }
  }, [yesterdayEarning, currency]);

  const accountBalanceFormatted = useMemo(() => {
    switch (currency) {
      case Currency.Bitcoin:
        return formatBtc(accountBalance, 6);
      case Currency.Kaspa:
        return formatKaspa(accountBalance, 3);
      default:
        return formatBtc(accountBalance);
    }
  }, [accountBalance, currency]);

  return (
    <Box sx={{ padding: '20px', display: 'flex', columnGap: '20px' }}>
      <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
        <Hashrate
          hashrateData={hashrate && hashrate.hashrateData}
          height={'400px'}
        />
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '20px',
          width: '40%',
        }}
      >
        <Box>
          <InfoCard
            styles={{
              height: { xl: '190px', md: '190px' },
            }}
            showDivider={false}
            subtitle={`${coin} Earnings`}
            content={
              <>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontWeight={500} color={'#7B7B7B'}>
                      Yesterday Earnings
                    </Typography>
                    <Typography
                      color="#1D4FBB"
                      fontSize="20px"
                      fontWeight={500}
                    >
                      {yesterdayEarningFormatted} {coin}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontWeight={500} color={'#7B7B7B'}>
                      Account Balance
                    </Typography>
                    <Typography
                      color="#1D4FBB"
                      fontSize="20px"
                      fontWeight={500}
                    >
                      {accountBalanceFormatted} {coin}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            }
          />
        </Box>
        <Box>
          <InfoCard
            styles={{
              height: { xl: '190px', md: '190px' },
            }}
            showDivider={false}
            subtitle={`${coin} Hashrate`}
            content={
              <>
                <Grid container>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontWeight={500} color={'#7B7B7B'}>
                      Real-time
                    </Typography>
                    {hashrate ? (
                      <Typography
                        color="#1D4FBB"
                        fontSize="20px"
                        fontWeight={500}
                      >
                        {hashrate.statistics.hashrateRealTime?.toFixed(2)} PH/s
                      </Typography>
                    ) : (
                      <Skeleton variant="rectangular" height={30} width="30%" />
                    )}
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <Typography fontWeight={500} color={'#7B7B7B'}>
                      Last 24 Hour
                    </Typography>
                    {hashrate ? (
                      <Typography
                        color="#1D4FBB"
                        fontSize="20px"
                        fontWeight={500}
                      >
                        {hashrate.statistics.hashrateLastDay.toFixed(2)} PH/s
                      </Typography>
                    ) : (
                      <Skeleton variant="rectangular" height={30} width="30%" />
                    )}
                  </Grid>
                </Grid>
              </>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};
