export const LockIcon = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Group">
      <g id="Group_2">
        <path
          id="Path"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.6665 28H9.33317C7.85984 28 6.6665 26.8067 6.6665 25.3333V14.6667C6.6665 13.1933 7.85984 12 9.33317 12H22.6665C24.1398 12 25.3332 13.1933 25.3332 14.6667V25.3333C25.3332 26.8067 24.1398 28 22.6665 28Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Path_2"
          d="M10.6665 12V9.33333V9.33333C10.6665 6.388 13.0545 4 15.9998 4V4C18.9452 4 21.3332 6.388 21.3332 9.33333V9.33333V12"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Path_3"
          d="M20 17.3332H12"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Path_4"
          d="M20 22.6667H12"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
);
