import { useEffect, useState } from 'react';
import { Layout } from 'components/Layout';
import { SectionHeading } from 'components/SectionHeading';
import Contracts from 'pages/Admin/AdminOperation/Contracts';
import { Customers } from 'pages/Admin/AdminOperation/Cutomers';
import { Miners } from 'pages/Admin/AdminOperation/Miners';
import { Operations } from 'pages/Admin/AdminOperation/Operations';
import { useAuth } from 'shared/hooks/useAuth';
import contractsService from 'shared/services/contracts.service';
import { Invoicing } from './Invoicing';
import { useNotification } from 'shared/hooks/useNotification';

export const AdminOperation = () => {
  const { user } = useAuth();
  const [statistics, setStatistics] = useState<any>(null);
  const { showSnackbar } = useNotification();

  const getAdminDashboardStatistics = async () => {
    try {
      const data = await contractsService.getAdminDashboardStatistics();
      setStatistics(data);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    getAdminDashboardStatistics();
  }, []);

  return (
    <Layout backgroundHeight="470px">
      <SectionHeading
        name={`Welcome back, ${user?.username}`}
        sx={{
          fontSize: {
            xs: 32,
            sm: 40,
            md: 48,
            lg: 52,
          },
          color: '#fff',
          marginBottom: '30px',
        }}
      />
      <Operations statistics={statistics} />
      <Customers />
      <Contracts />
      <Miners
        machineByLocation={statistics?.machineByLocation || []}
        machinePerTypeData={statistics?.machineByMachineType || []}
      />
      <Invoicing />
    </Layout>
  );
};
