import { KeyboardArrowLeft } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { Layout } from 'components/Layout';
import { SectionHeading } from 'components/SectionHeading';
import { TransactionsTable } from 'pages/Affiliate/AffiliateTransactions/TransactionsTable';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { useNotification } from 'shared/hooks/useNotification';
import BitcoinService from 'shared/services/bitcoin.service';

export const AffiliateTransactions = () => {
  const navigate = useNavigate();

  const [transactions, setTransactions] = useState<any>(null);
  const { showSnackbar } = useNotification();

  const getWalletData = async () => {
    try {
      const { transactions } = await BitcoinService.getWalletData({});

      return setTransactions(transactions);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    getWalletData();
  }, []);

  return (
    <Layout backgroundHeight="780px">
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '60px' }}>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Button
            sx={{
              borderColor: '#FBFBFB',
              borderRadius: 2,
              padding: '5px',
              minWidth: 'unset',
            }}
            variant="outlined"
            onClick={() => navigate(routesMapping.affiliateOperation)}
          >
            <KeyboardArrowLeft />
          </Button>
          <SectionHeading
            sx={{
              color: 'white',
            }}
            name="Transaction history"
          />
        </Box>
        <TransactionsTable transactions={transactions} />
      </Box>
    </Layout>
  );
};
