import { useState } from 'react';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Progress } from 'components/Progress';
import { useNotification } from 'shared/hooks/useNotification';
import * as yup from 'yup';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import AuthService from 'shared/services/auth.service';
import { LockIcon } from 'components/Icons/Lock';
import { authButtonStyles } from 'shared/styles/button';

export const schema = yup.object().shape({
  confirmationCode: yup.string().min(6).required('Please enter the code'),
});

export const ConfirmationCode = (props: any) => {
  const { setStep, email, setEmailToken } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { showSnackbar } = useNotification();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (payload: any) => {
    setIsLoading(true);
    try {
      const { emailToken } = await AuthService.confirmEmailCode({
        ...payload,
        email,
      });
      setEmailToken(emailToken);
      setStep('reset');
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendEmail = async () => {
    try {
      await AuthService.forgotPassword({ email });
      showSnackbar('Email with the link has been sent', 'success');
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  return (
    <form className="contentLogin" onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{ display: 'flex', width: '100%', marginBottom: '15px' }}>
          <Typography
            color=""
            variant="h2"
            sx={{
              color: '#fff',
              fontSize: '48px',
              fontWeight: '600',
              textAlign: 'start',
            }}
          >
            Check your Email
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginBottom: '30px' }}>
          <Typography
            variant="h3"
            sx={{
              color: '#95DA3E',
              fontSize: '12px',
              display: 'flex',
              columnGap: '4px',
            }}
          >
            We have sent a confirmation code to change your password to{' '}
            <Box sx={{ color: '#717371' }}>{email[0]}*********.com</Box>
          </Typography>
        </Box>
        <Box className="dataContainer">
          <Box className="dataBox">
            <ControlledTextField
              control={control}
              name="confirmationCode"
              error={errors}
              placeholder="Code"
              dark
              textFieldProps={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}>
        <Button
          sx={authButtonStyles}
          variant="contained"
          color="secondary"
          type="submit"
          fullWidth
        >
          {!isLoading ? 'Confirm' : <Progress color="inherit" size={20} />}
        </Button>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            color: '#ffffff66',
          }}
        >
          <Box onClick={() => setStep('email')} sx={{ cursor: 'pointer' }}>
            Change Email
          </Box>
          <Box onClick={() => handleResendEmail()} sx={{ cursor: 'pointer' }}>
            Resend Email
          </Box>
        </Box>
      </Box>
    </form>
  );
};
