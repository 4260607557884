export const cardStyles = {
  border: '1px solid white',
  borderRadius: 5,
  overflow: 'hidden',
  backgroundColor: 'white',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};
